define('common/extensions/jqNavigationExtensions',["jquery", "knockout"], function ($, ko) {
    "use strict";

    $.extend({
        openInTab: function (route) {
            $.openUrlInTab(route.url);
        },

        openUrlInTab: function (url) {
            window.open(url);
        },

        navigateTo: function (route) {
            $.navigateToUrl(route.url);
        },

        navigateToUrl: function (url) {
            try {
                window.location.href = url;
            } catch (e) {
                e.message = "Failed to navigate to URL " + url + ": " + e.message;
                throw e;
            }
        }
    });
});

