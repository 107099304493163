define('common/widgets/navBar',["jquery", "knockout"], function ($, ko) {
    "use strict";

    var DURATION = 300;

    function NavBar(element) {
        var that = this;

        that._$element = $(element);
        that._$children = that._$element.children();

        that.isOpen = ko.observable(false);
        that.isOpen.subscribe(_toggleOpen.bind(that));

        if ($("#mask").length === 0) {
            $("body").append("<div id='mask'></div>");
        }
        that._$mask = $("#mask");

        // Debugging
        if ($.type(that._$mask.finish) !== "function") {
            var type = $.type(that._$mask.finish);
            var maskType = $.type(that._$mask);
            throw new Error(
                "For some reason, that._$mask.finish is of type " + type + ". " +
                "Mask is of type " + maskType + " and has the value " + that._$mask
            );
        }

        $(document).on("click.nav-bar", "#mask", function () {
            that.close();
        });
    }

    NavBar.prototype.open = function () {
        var that = this;

        that.isOpen(true);
    };

    NavBar.prototype.close = function () {
        var that = this;

        that.isOpen(false);
    };

    NavBar.prototype.toggle = function (vm, event) {
        var that = this;

        that.isOpen(!that.isOpen());
        event.preventDefault();
    };

    NavBar.prototype.toggleSubmenu = function (element, event) {
        var that = this;

        var $submenu = $(event.target).closest("a").next(".nav-submenu");

        if (!that.isOpen() && !$submenu.is(":visible")) {
            that.open();
        }

        $submenu.slideToggle();
    };

    function _toggleOpen(isOpening) {
        var that = this;

        if (isOpening) {
            that._$mask.finish().show().css("opacity", 0.01).fadeTo(DURATION, 0.7);

            that._$children.stop().fadeIn();
            that._$element.finish().animate({
                duration: DURATION,
                width: "300px"
            }, function () {
                that._$element.addClass("is-completely-open");
            });
            that._$element.addClass("is-open");
        } else {
            that._$mask.finish().show().css("opacity", 0.7).fadeOut();

            that._$element.removeClass("is-completely-open");
            that._$children.stop().fadeOut();
            that._$element.finish().animate({
                duration: DURATION,
                width: "0px"
            });
            that._$element.removeClass("is-open");
        }
    }

    return NavBar;
});

