define('common/select/theaterSelector',[
    "jquery",
    "knockout",
    "common/select/searchSelector",
    "common/search/theaterSearcher",
    "common/select/circuitSelector",
    "common/select/sourceSelector",
    "common/select/countrySelector"
], function ($, ko, SearchSelector, TheaterSearcher, CircuitSelector, SourceSelector, CountrySelector) {
    "use strict";

    function TheaterSelector(country, options, id) {
        var that = this;

        var linkedSelectors = {};

        for (var i = 1; i < arguments.length; i++) {
            var argument = arguments[i];

            if (argument instanceof CircuitSelector) {
                linkedSelectors.linkedCircuit = argument.val;
            } else if (argument instanceof SourceSelector) {
                linkedSelectors.linkedSource = argument.val;
            } else if (!(argument instanceof CountrySelector)) {
                options = arguments[i];
                id = arguments.length > i ? arguments[i + 1] : null;
                break;
            }
        }

        options = options || {};
        $.extend(true, options, linkedSelectors);

        id = id || "theater-selector";
        that.searcher = new TheaterSearcher(country, id, options.searcher);
        SearchSelector.call(that, options, id, that.searcher);

        that.searcher.circuit.parent = that;
        that.searcher.circuit.val.subscribe(_onCircuitChange.bind(that));

        that.searcher.countryId.subscribe(_onCountryChange.bind(that));
    }

    TheaterSelector.prototype = Object.create(SearchSelector.prototype);

    TheaterSelector.prototype.selectResult = function (result, preventClose) {
        var that = this;

        var term = that.searcher.searchTerm();
        if (result && term && that.searcher._countrySelector
            && (result.id.toString() === term.trim() || (result.boeId && result.boeId.toString() === term.trim()))) {
            that.searcher._countrySelector.selectResult({id: result.countryId, name: result.country});
        }

        SearchSelector.prototype.selectResult.call(that, result, preventClose);
    };

    function _onCountryChange(countryId) {
        var that = this;

        if (countryId && that.val() && that.val().countryId !== countryId) {
            var required = that.isRequired();
            that.isRequired(false);
            that.clearSelection();
            that.isRequired(required);
        }
    }

    function _onSourceChange(originalCallback, source) {
        var that = this;

        originalCallback(source);

        if (source && that.isSelected()) {
            that.selectResult(null, true);
        }
    }

    function _onCircuitChange(circuit) {
        var that = this;

        if (circuit && that.val() && that.val().circuitId !== circuit.id) {
            that.selectResult(null, true);
        }
    }

    return TheaterSelector;
});

