define('common/select/searchSelector',[
    "jquery",
    "knockout",
    "common/storage",
    "common/select/selector",
    "common/utils/binarySorter"
], function ($, ko, Storage, Selector, Sorter) {
    "use strict";

    var activeSelectorStack = [];

    var SORTER = new Sorter({
        sortBy: ["name", "id"]
    });

    var FADE_DURATION = 200;
    var TARGET_WIDTH = 1024;

    function SearchSelector(options, id, searcher) {
        this.searcher = searcher;

        var that = Selector.call(this, options, id);

        options = options || {};

        that._$input = $(that._id + "-primary-input");
        that._$advancedOptions = $(that._id + "-advanced-options");
        that._$dropdown = $(that._id + "-content");
        that._$dropdownWrapper = that._$dropdown.children(".results-wrapper");
        that._isFirstOpen = true;

        that.searcher.onSelected = that.selectResult.bind(that);
        that.searcher.enableQuicksearch();

        that.isActive = ko.observable(false);
        that.isTopLevel = ko.observable(false);
        that.isLoading = ko.observable(false);

        that._initDefaultOptions(options);

        that._createDomEventHandlers();

        return that;
    }

    SearchSelector.prototype = Object.create(Selector.prototype);

    SearchSelector.prototype.focus = function () {
        var that = this;

        that._$input.focus();
    };

    SearchSelector.prototype.onFocusPrimaryInput = function () {
        var that = this;

        var stack = activeSelectorStack;
        var top = stack.length > 0 ? stack[stack.length - 1] : null;

        if (top === that) {
            return;
        }

        _clearStack(top && top.parent === that ? that : that.parent);
        that.open();
    };

    SearchSelector.prototype.open = function () {
        var that = this;

        open(that);
    };

    SearchSelector.prototype.close = function () {
        var that = this;

        close(that);
    };

    SearchSelector.prototype.selectResult = function (result, preventClose) {
        var that = this;

        if (that.isMultiselect()) {
            if (result) {
                var length = that.vals().length;
                if (that.sorter()) {
                    that.sorter().insert(result, that.vals);
                } else {
                    that.vals.push(result);
                }

                if (that.vals().length > length && $.type(that.onValueAdded) === "function") {
                    that.onValueAdded(result);
                }
            }
        } else if ((!result && that.isRequired()) || (result && that._countryDoesNotMatch(result))) {
            return;
        } else {
            var storeResult = true;
            if (JSON.stringify(result) !== JSON.stringify(that._prev) || that.allowRetrigger()) {
                var temp = that.val();
                if (!that.triggersOnly()) {
                    that.val(result);
                }
                storeResult = that.triggerChange(result);
                if (!storeResult) {
                    that.val(result = temp);
                }
            }

            that._storeResultLocally(result);

            that._$input.blur();

            that._prev = result;
        }

        if (preventClose !== true && !that.allowRetrigger()) {
            that.close();
        }
    };

    SearchSelector.prototype._storeResultLocally = function (result) {
        var that = this;

        if (that._useLocalStorage()) {
            if (Storage.local.enabled()) {
                if ($.defined(result)) {
                    Storage.local.store(that._storageId, JSON.stringify(result));
                } else {
                    Storage.local.remove(that._storageId);
                }
            } else {
                // TODO!
            }
        }
    };

    SearchSelector.prototype.clearSelection = function () {
        var that = this;

        that.vals([]);
        that._prev = undefined;
        that.searcher.searchTerm(null);
        that.searcher.clearResults();
        that.selectResult(null, true);
    };

    SearchSelector.prototype.canClearSelection = function () {
        var that = this;

        return that.isSelected() && !that.isRequired();
    };

    SearchSelector.prototype.isSelected = function () {
        var that = this;

        return that.isMultiselect() ? that.vals().length > 0 : !!that.val();
    };

    SearchSelector.prototype.quickSearch = function () {
        var that = this;

        if (that._qsa) {
            that._qsa.abort();
        }

        that._qsa = that.searcher.search();
    };

    SearchSelector.prototype.triggerChange = function (result) {
        var that = this;

        if ($.type(that.onChange) === "function") {
            var storeResult = that.onChange(result, that);
            if ($.defined(storeResult)) {
                return !!storeResult;
            }
        }

        return true;
    };

    SearchSelector.prototype._initDefaultOptions = function (options) {
    };

    SearchSelector.prototype._initialize = function () {
        var that = this;

        that.val = ko.observable();
        that.vals = ko.observableArray();

        that.isRequired = ko.observable(false);
        that.isMultiselect = ko.observable(false);
        that.sorter = ko.observable(SORTER);
        that.isInline = ko.observable(false);
        that.isSmall = ko.observable(false);
        that.allowRetrigger = ko.observable(false);
        that.triggersOnly = ko.observable(false);
        that._useLocalStorage = ko.observable(false);
        that._useLocalStorage.subscribe(function (value) {
            if (!value) {
                return;
            }

            var stored = Storage.local.retrieve(that._storageId);
            if (stored === "null") {
                stored = null;
            }
            stored = stored ? JSON.parse(stored) : null;
            if (that._countryDoesNotMatch(stored)) {
                stored = null;
            }

            that.val(stored);
            that._prev = that.val();
        });
    };

    SearchSelector.prototype._countryDoesNotMatch = function (value) {
        var that = this;

        if (!value || !value.hasOwnProperty("countryId") || !that.searcher || !that.searcher.countryId
            || (that.searcher.hasOwnProperty("includeDomestic") && that.searcher.includeDomestic()
                && that.searcher.countryId() === 'US' && ['US', 'CA', 'PR'].indexOf(value.countryId) !== -1)) {
            return false;
        }

        return that.searcher.countryId() && value.countryId !== that.searcher.countryId();
    };

    SearchSelector.prototype._readOptions = function (options) {
        var that = this;

        if (options.hasOwnProperty("isRequired")) {
            that.isRequired(!!options.isRequired);
        }

        if (options.hasOwnProperty("isMultiselect")) {
            that.isMultiselect(!!options.isMultiselect);
        }

        if (options.hasOwnProperty("sorter")) {
            that.sorter(options.sorter);
        }

        if (options.hasOwnProperty("isInline")) {
            that.isInline(!!options.isInline);
        }

        if (options.hasOwnProperty("isSmall")) {
            that.isSmall(!!options.isSmall);
        }

        if (options.hasOwnProperty("allowRetrigger")) {
            that.allowRetrigger(!!options.allowRetrigger);
        }

        if (options.hasOwnProperty("triggersOnly")) {
            that.triggersOnly(!!options.triggersOnly);
        }

        if (options.hasOwnProperty("parent") && options.parent instanceof SearchSelector) {
            that.parent = options.parent;
        }

        if (options.hasOwnProperty("useLocalStorage")) {
            that._useLocalStorage(!!options.useLocalStorage);
        }
    };

    SearchSelector.prototype._readParams = function (params) {
        var that = this;

        if ((!that._useLocalStorage() || !$.isEmptyObject(params)) && !that._countryDoesNotMatch(params)) {
            that.val(params && !$.isEmptyObject(params) ? params : null);
            that._prev = that.val();

            if (that.val()) {
                that._storeResultLocally(that.val());
            }
        }
    };

    SearchSelector.prototype._createDomEventHandlers = function () {
        var that = this;

        var id = that._$input.attr("id");
        $(document).on("click." + id, "#" + id, function () {
            if (that._$input[0] === document.activeElement) {

            }
        });

        $(document).on("keypress." + id, "#" + id, function (event) {
            if (event.which === 13) {
                that.searcher.selectMarkedResult();
            }
        });

        $(document).on("keyup." + id, "#" + id, function (event) {
            if (event.which === 40) {
                that.searcher.moveMarkedResultDown();
                that._$input.select();
            } else if (event.which === 38) {
                that.searcher.moveMarkedResultUp();
                that._$input.select();
            }
        });
    };

    SearchSelector.prototype._deselectValue = function (value) {
        var that = this;

        var length = that.vals().length;

        that.vals.remove(value);

        if (that.vals().length < length && $.type(that.onValueRemoved) === "function") {
            that.onValueRemoved(value);
        }
    };

    // Close selectors on Escape or click

    $(document).on("keyup.search-selector", function (event) {
        if (event.keyCode === 27) {
            _clearStack();
        }
    });

    $(document).on("click.search-selector", function (event) {
        var $target = $(event.target);

        if (!$target.is(".input-button")
            && !$target.parent().is(".input-button")
            && !$target.is(".selector-primary-input")
            && $target.closest(".results-wrapper").length <= 0) {
            _clearStack();
        }
    });

    // Dropdown stack (handles nested dropdowns)

    function inStack(selector) {
        return activeSelectorStack.indexOf(selector) >= 0;
    }

    function open(selector) {
        if ((selector.isActive() && selector.isTopLevel())) {
            return;
        }

        _removeFromStack(selector);

        activeSelectorStack.push(selector);

        if (!selector.isActive()) {
            selector.isActive(true);

            if (!selector.isInline()) {
                var $input = selector._$input;
                var $dropdown = selector._$dropdown;
                var $wrapper = selector._$dropdownWrapper;
                if (selector._isFirstOpen) {
                    $dropdown.appendTo("body");
                    $dropdown.wrap("<div class='text-center'></div>")
                    $wrapper.click(function (event) {
                        var $target = $(event.target);
                        if ($target.is(".selector-primary-input, .input-button, .input-button > .fal")
                            && $target.closest($wrapper).length > 0) {
                            return;
                        }
                        if (selector.isActive()) {
                            _clearStack(selector);
                        }
                    });
                    selector._isFirstOpen = false;
                }
                $dropdown.css("top", $input.offset().top + $input.outerHeight());

                var left = $input.offset().left;
                var width = $dropdown.parent().width();
                if (width <= TARGET_WIDTH) {
                    $dropdown.css("left", "16px");
                    $dropdown.css("right", "16px");
                    $dropdown.css("width", "auto");
                } else if (left + TARGET_WIDTH > width) {
                    $dropdown.css("left", "auto");
                    $dropdown.css("right", "16px");
                    $dropdown.css("width", "1024px");
                } else {
                    $dropdown.css("left", left + "px");
                    $dropdown.css("right", "auto");
                    $dropdown.css("width", "1024px");
                }

                $wrapper.fadeIn(FADE_DURATION);
            }
        }

        selector._$input.select();

        _refreshTopLevel();
    }

    function close(selector) {
        if (selector.allowRetrigger()) {
            return;
        }

        _removeFromStack(selector);

        selector.isActive(false);
        selector.isTopLevel(false);
        if (!selector.isInline()) {
            selector._$dropdownWrapper.fadeOut(FADE_DURATION);
        }
        selector._$input.blur();

        _refreshTopLevel();
    }

    // Dropdown stack helper functions

    function _removeFromStack(selector) {
        var index = activeSelectorStack.indexOf(selector);
        if (index < 0) {
            return;
        }
        activeSelectorStack.splice(index, 1);
    }

    function _clearStack(stopOnSelector) {
        while (activeSelectorStack.length > 0) {
            var selector = activeSelectorStack[activeSelectorStack.length - 1];
            if (stopOnSelector && selector === stopOnSelector) {
                break;
            }
            close(activeSelectorStack.pop());
        }
    }

    function _clearOthers(selector) {
        _removeFromStack(selector);
        _clearStack(selector.parent);
        if (selector) {
            activeSelectorStack.push(selector);
        }
    }

    function _refreshTopLevel() {
        if (activeSelectorStack.length <= 0) {
            return;
        }

        var selector = activeSelectorStack[activeSelectorStack.length - 1];
        selector.isTopLevel(true);

        var $wrapper = selector._$dropdownWrapper;
        $wrapper.fadeIn(FADE_DURATION);

        for (var i = activeSelectorStack.length - 2; i >= 0; i--) {
            activeSelectorStack[i].isTopLevel(false);
        }
    }

    $(window).on("resize.selector", function () {
        _clearStack();
    });

    return SearchSelector;
});

