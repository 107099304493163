define('common/select/selector',["jquery", "knockout"], function ($, ko) {
    "use strict";

    function Selector(options, id) {
        var that = this;

        if (id) {
            that._id = "#" + id;
            that._storageId = "selected-" + id.replace(/-selector$/, "");
        }

        var $params = $(that._id + "-initial-params");
        var params = $params.length > 0 ? JSON.parse($params.val()) : null;

        that._initialize();
        that._readOptions(options || {});
        that._readParams(params || {});

        return that;
    }

    Selector.prototype.isSelected = function () {
        throw new Error("Must be overridden!");
    };

    Selector.prototype._initialize = function () {
    };

    Selector.prototype._readOptions = function (options) {
    };

    Selector.prototype._readParams = function (params) {
    };

    return Selector;
});

