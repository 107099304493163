define('common/search/searcher',[
    "jquery",
    "knockout",
    "js-routes",
    "js-messages",
    "common/widgets/pagination"
], function (
    $,
    ko,
    jsRoutes,
    jsMessages,
    Pagination
) {
    "use strict";

    function Searcher(type, options, country) {
        var that = this;

        options = $.type(options) === "object" ? options : {};

        that._type = type;

        that._bindCountry(country, options);

        that.isLive = ko.observable(true);
        that.isLocked = ko.observable(false);

        that.searchTerm = ko.observable();

        that.sortingColumn = ko.observable();
        that.invertSort = ko.observable(false);

        that.maxResultsPerPage = ko.observable(null);

        that._results = ko.observableArray();
        that._filteredResults = ko.computed(_filteredResultsComputed.bind(that));
        that._sortedResults = ko.computed(_sortedResultsComputed.bind(that));
        that._pagination = new Pagination(that._sortedResults, that.maxResultsPerPage);
        that._previousSortColumn = undefined;
        that.results = that._pagination.view();
        that.hasResults = ko.pureComputed(_hasResultsComputed.bind(that));

        that.markedResult = ko.observable(0);
        that.results.subscribe(_updateMarkedResultSubscription.bind(that));

        that.timings = ko.observableArray();
        that.showTimings = ko.observable(false);

        that.isInitialized = ko.observable(false);
        that._isInitialized = false;
        that.isSearching = ko.observable(false);
        that.hasSelection = ko.observable(false);

        that.waitingOn = ko.observable(parseOption(options, "waitingOn", null));
        that.enableOn = ko.observable(parseOption(options, "enableOn", true));
        that.isWaiting = ko.computed(_isWaitingComputed.bind(that));
        that.isEnabled = ko.computed(_isEnabledComputed.bind(that));

        var sortToTop = parseOption(options, "sortToTop", {});
        that._sortToTopField = ko.observable(parseOption(sortToTop, "field", null));
        that._sortToTopFields = ko.observableArray(parseOption(sortToTop, "fields", null));
        that._borderIndex = ko.observable(null);

        that.onInit = parseOption(options, "onInit");

        that.withUnderlyingUser = !!options.withUnderlyingUser;

        that.load();

        return that;
    }

    Searcher.prototype.load = function () {
        var that = this;

        if (that._loadAjax) {
            that._loadAjax.abort();
        }

        that._loadAjax = $.ajaxr(that._initRoute()).done(function (data) {
            that._loadAjax = null;

            if (that._onLoad) {
                that._onLoad(data);
            }

            that.initialize(data);
            that.isInitialized(true);
            that._isInitialized = true;

            if (that._enableQuicksearchOnLoad) {
                that._enableQuicksearch();
            }
        });
    };

    Searcher.prototype.initialize = function (data) {
        var that = this;

        var results = data.results;

        var sortToTopField = that._sortToTopField();
        var sortToTopFields = that._sortToTopFields();
        if (sortToTopField && sortToTopFields && results) {
            var matchingItems = [];
            var i = results.length;
            while (i--) {
                if (sortToTopFields.indexOf(results[i][sortToTopField]) >= 0) {
                    matchingItems.unshift(results.splice(i, 1)[0]);
                }
            }

            results.unshift.apply(results, matchingItems);
            that._borderIndex(matchingItems.length - 1);
        }

        that.setResults(results || []);
        if (data.hasOwnProperty("isLive")) {
            that.isLive(!!data.isLive);
        }
        that.timings(data.timings || []);

        if ($.type(that.onInit) === "function") {
            that.onInit.call(that, data);
        }
    };

    Searcher.prototype.search = function () {
        var that = this;

        if (!that.isLive()) {
            return;
        }

        that.isSearching(true);

        if (that._searchTimeout) {
            clearTimeout(that._searchTimeout);
            that._searchTimeout = null;
        }

        var route = that._searchRoute();
        that.hasSelection(!!route);
        if (!route) {
            that.isSearching(false);
            that.focusPrimaryInput();
            return;
        }

        that._searchTimeout = setTimeout(function () {
            if (that._searchAjax) {
                that._searchAjax.abort();
                that._searchAjax = null;
            }

            that._searchAjax = $.ajaxr(route).done(function (data) {
                that._pagination.page(0);
                that.setResults(data.results || []);
                that.timings(data.timings || []);
                that.isSearching(false);

                that.focusPrimaryInput();
                that.markedResult(0);
            });
        }, 500);
    };

    Searcher.prototype.selectResult = function (result) {
        var that = this;

        that.markedResult(0);
        if (that.onSelected) {
            that.onSelected(result);
        } else {
            console.log(result);
        }
    };

    Searcher.prototype.showResults = function () {
        var that = this;

        return !that.isSearching() && that.hasResults();
    };

    Searcher.prototype.moveMarkedResultUp = function () {
        var that = this;

        if (that.markedResult() > 0) {
            that.markedResult(that.markedResult() - 1);
        }
    };

    Searcher.prototype.moveMarkedResultDown = function () {
        var that = this;

        if (that.markedResult() < that.results().length - 1) {
            that.markedResult(that.markedResult() + 1);
        }
    };

    Searcher.prototype.selectMarkedResult = function () {
        var that = this;

        if (that.isSearching()) {
            return;
        }

        var index = that.markedResult();
        if ($.defined(index) && index < that.results().length) {
            that.selectResult(that.results()[index]);
        }
    };

    Searcher.prototype.hasBottomBorder = function (index) {
        var that = this;

        return that._borderIndex() === ko.unwrap(index);
    };

    Searcher.prototype.isMarked = function (index) {
        var that = this;

        return that.markedResult() === ko.unwrap(index);
    };

    Searcher.prototype.hasResults = function () {
        var that = this;

        return that.results().length > 0;
    };

    Searcher.prototype.enableQuicksearch = function () {
        var that = this;

        that.disableQuicksearch();

        if (that._loadAjax) {
            that._loadAjax.done(function () {
                that._enableQuicksearch();
            });
        } else {
            that._enableQuicksearchOnLoad = true;
        }
    };

    Searcher.prototype._enableQuicksearch = function () {
        var that = this;

        that._quickSearchComputed = ko.computed(function () {
            if (that.isLive()) {
                that.search();
            }
        });
    };

    Searcher.prototype.disableQuicksearch = function () {
        var that = this;

        if (that._quickSearchComputed) {
            that._quickSearchComputed.dispose();
        }
        delete that._quickSearchComputed;
    };

    Searcher.prototype.home = function () {
        $.navigateTo(jsRoutes.controllers.sites.primary.Home.index());
    };

    Searcher.prototype.focusPrimaryInput = function () {
        var that = this;

        $("#" + that._type + "-search-name-field").focus();
    };

    Searcher.prototype.getRowCss = function () {
        return "";
    };

    Searcher.prototype.getFormattedDate = function (date) {
        return date ? $.toLocaleDate($.stringToDate(date)) : "";
    };

    Searcher.prototype.setResults = function (results) {
        var that = this;

        that._previousSortColumn = null;
        that._results(results);

        return that;
    };

    Searcher.prototype.clearResults = function () {
        var that = this;

        if (that.isLive()) {
            that.setResults([]);
        }
    };

    Searcher.prototype._initRoute = function () {
        throw new Error("Must be overridden!");
    };

    Searcher.prototype._searchRoute = function () {
        throw new Error("Must be overridden!");
    };

    Searcher.prototype._bindCountry = function (country, options) {
        var that = this;

        if (!country) {
            return;
        }

        var CountrySelector = require("common/select/countrySelector");
        if (country instanceof CountrySelector) {
            that._countrySelector = country;
            country = country.val;
        } else if (!_isStaticCountryObject(ko.unwrap(country)) && !_isStaticCountryId(ko.unwrap(country))) {
            throw new Error("Country parameter must be a country selector, JSON object, or ID!");
        }

        if (!options.hasOwnProperty("waitingOn") && ko.isObservable(country)) {
            options.waitingOn = function () {
                return ko.unwrap(country) ? null : jsMessages(that.waitingOnCountryI18nKey || "FIXME");
            };
        }

        that.countryId = ko.pureComputed(function () {
            var _country = ko.unwrap(country);
            return $.type(_country) === "object" && _country.hasOwnProperty("id") ? _country.id : _country;
        });
        that.countryId.subscribe(that._onCountryChanged.bind(that));
    };

    function _isStaticCountryId(country) {
        return $.type(country) === "string" && /^[A-Z]{2}$/.test(country);
    }

    function _isStaticCountryObject(country) {
        return $.type(country) === "object" && country.hasOwnProperty("id") && _isStaticCountryId(country.id);
    }

    Searcher.prototype._onCountryChanged = function () {
        var that = this;

        that.clearResults();
    };

    function _isEnabledComputed() {
        var that = this;

        if (that.isLocked() || !that.isInitialized() || that.isWaiting()) {
            return false;
        }

        var enableOn = that.enableOn();
        return $.type(enableOn) === "function" ? enableOn() : !!enableOn;
    }

    function _isWaitingComputed() {
        var that = this;

        var waitingOn = that.waitingOn();
        return $.type(waitingOn) === "function" ? waitingOn() : !!waitingOn;
    }

    function _filteredResultsComputed() {
        var that = this;

        if (that.isLive() || !that.searchTerm()) {
            return that._results();
        }

        return that._results().filter(function (result) {
            var searchTerm = that.searchTerm().toLowerCase();

            if (result._searchSortingTerms) {
                return result._searchSortingTerms.some(function (term) {
                    if (term.toLowerCase().indexOf(searchTerm) >= 0) {
                        return true;
                    }
                });
            } else {
                for (var key in result) {
                    if (!result.hasOwnProperty(key)) {
                        continue;
                    }
                    var value = result[key];
                    if ($.type(value) === "string") {
                        if (value.toLowerCase().indexOf(searchTerm) >= 0) {
                            return true;
                        }
                    }
                }
            }

            return false;
        });
    }

    function _sortedResultsComputed() {
        var that = this;

        var column = that.sortingColumn();
        var invertSort = that.invertSort();

        if (!column) {
            return that._filteredResults();
        }

        if (that._previousSortColumn && that._previousSortColumn === column) {
            that._previousSortColumn = undefined;
            invertSort = true;
        } else {
            that._previousSortColumn = column;
        }

        return that._filteredResults().sort(function (a, b) {
            var aVal = a[column], bVal = b[column];

            if (!$.defined(aVal) && !$.defined(bVal)) {
                return 0;
            }

            if ($.type(aVal) === "string") {
                aVal = aVal.toLowerCase();
            }
            if ($.type(bVal) === "string") {
                bVal = bVal.toLowerCase();
            }

            if (!$.defined(aVal) || aVal < bVal) {
                return invertSort ? 1 : -1;
            } else if (!$.defined(bVal) || aVal > bVal) {
                return invertSort ? -1 : 1;
            } else {
                return 0;
            }
        });
    }

    function _hasResultsComputed() {
        var that = this;

        return that.results().length > 0;
    }

    function _updateMarkedResultSubscription(results) {
        var that = this;

        if (that.markedResult() >= results.length) {
            that.markedResult(0);
        }
    }

    function parseOption(options, field, defaultValue) {
        if (options && options.hasOwnProperty(field)) {
            return options[field];
        } else {
            return defaultValue;
        }
    }

    return Searcher;
});

