define('common/widgets/modals',["jquery", "knockout"], function ($, ko) {
    "use strict";

    var stack = [];

    $(document).on("keyup.modal", function (event) {
        if (event.keyCode === 27) {
            $(".modal-open .modal.closable").modal("close");
        }
    });

    $(document).on("click.modal", ".modal.closable", function (event) {
        var $this = $(this);

        if (stack.length <= 0 || !$(event.target).is(".modal")) {
            return;
        }

        $this.modal("close");
    });

    $(document).on("click.modal-button", ".button.modal-close", function (event) {
        var $this = $(this);

        if (stack.length <= 0) {
            return;
        }

        $this.closest(".modal.closable").modal("close");
    });

    $.fn.extend({
        modal: function (command, onEndTransitionCallback, onStartTransitionCallback) {
            var $this = $(this);

            if ($this.length === 0) {
                return;
            }

            var index = stackIndexOf($this);
            if (!command || command === "open") {
                $this.fadeIn(400, onEndTransitionCallback);

                if (index >= 0) {
                    stack.splice(index, 1);
                } else if (stack.length === 0) {
                    $("body").addClass("modal-open");
                } else {
                    stack[0].fadeOut(400);
                }

                stack.unshift($this);
            } else if (command === "close" && index > 0) {
                stack.splice(index, 1);
                onClose($this);
                if (onEndTransitionCallback) {
                    onEndTransitionCallback();
                }
                onCloseFinish($this);
            } else {
                $this.fadeOut(400, function () {
                    if (onEndTransitionCallback) {
                        onEndTransitionCallback();
                    }
                    onCloseFinish($this);
                });
                stack.shift();

                if (stack.length > 0) {
                    stack[0].show();
                } else {
                    $("body").removeClass("modal-open");
                }
                onClose($this);
            }

            if (onStartTransitionCallback) {
                onStartTransitionCallback();
            }
        }
    });

    function onClose($this) {
        var callable = $this.data("on-close");
        if (!callable) {
            return;
        }

        var context = ko.dataFor($this[0]);
        context[callable]();
    }

    function onCloseFinish($this) {
        var callable = $this.data("on-close-finished");
        if (!callable) {
            return;
        }

        var context = ko.dataFor($this[0]);
        context[callable]();
    }

    function stackIndexOf($this) {
        if (stack.length <= 0) {
            return -1;
        }

        for (var i = 0; i < stack.length; i++) {
            if (stack[i][0] == $this[0]) {
                return i;
            }
        }
    }
});

