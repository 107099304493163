define('common/extensions/koDatepickerBindings',[
    "jquery",
    "knockout",
    "js-messages",
    "js-week-definitions",
    "flatpickr"
], function ($, ko, jsMessages, jsWeekDefinitions, Flatpickr) {
    "use strict";

    Flatpickr.l10ns.default.rangeSeparator = " > ";

    ko.bindingHandlers.date = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var args = parseArguments(valueAccessor());
            var value = args.value;

            if (ko.isObservable(value)) {
                args.onChange = args.onChange || function (selectedDates) {
                    value(selectedDates[0]);
                };
            }

            datePicker(element, args, allBindings, bindingContext);
        },
        update: _update
    };

    ko.bindingHandlers.dateTime = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var args = valueAccessor();
            args.enableTime = true;
            args = parseArguments(args);

            var value = args.value;
            if (ko.isObservable(value)) {
                args.onChange = args.onChange || function (selectedDates) {
                    value(selectedDates[0]);
                };
            }

            datePicker(element, args, allBindings, bindingContext);
        },
        update: _update
    };

    ko.bindingHandlers.dateRange = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            dateRangePicker(element, valueAccessor(), allBindings, bindingContext);
        },
        update: _update
    };

    ko.bindingHandlers.playweek = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var args = valueAccessor();
            args.maxRange = 8;
            args.max = args.max || new Date();

            var subscriptions = [];
            var weekDefinition = null;
            if (args.theater) {
                if (args.theater.isSelected()) {
                    weekDefinition = jsWeekDefinitions.get(args.theater.val().countryId);
                }
                subscriptions.push(args.theater.val.subscribe(function (theater) {
                    weekDefinition = theater ? jsWeekDefinitions.get(theater.countryId) : null;
                }));
            }

            dateRangePicker(element, args, allBindings, bindingContext);

            ko.utils.domNodeDisposal.addDisposeCallback(pickers.element, function () {
                for (var i = 0; i < subscriptions.length; i++) {
                    subscriptions[i].dispose();
                }
            });
        },
        update: _update
    };

    function datePicker(element, args, allBindings, bindingContext) {
        var $element = $(element);

        var value = args.value;
        var offset = args.offset;
        delete args.value;

        var modeClass = (args.mode ? args.mode : "single") + "-date-input";
        $element.addClass("input text");
        $element.wrap("<div class=\"date-input " + modeClass + " input-with-icon left\"></div>");
        $element.before("<i class=\"input-icon left fal fa-calendar-alt\"></i>");

        var max, min;
        if (ko.isObservable(args.max)) {
            max = args.max;
            delete args.max;
        }
        if (ko.isObservable(args.min)) {
            min = args.min;
            delete args.min;
        }

        if ($.type(args.onOpen) === "function") {
            args.onOpen = [args.onOpen];
        } else if ($.type(args.onOpen) !== "array") {
            args.onOpen = [];
        }

        args.onOpen.push(function (selectedDates, dateStr, picker) {
            $(".flatpickr-input.active").each(function () {
                if (this._flatpickr === picker) {
                    return;
                }
                this._flatpickr.close();
            });
        });

        var picker = Flatpickr($element[0], args);

        var subscriptions = [];

        if (ko.isObservable(value)) {
            subscriptions.push(value.subscribe(function (value) {
                picker.setDate(value);
            }));

            $element.parent(".input-with-icon").addClass("right");
            if (bindingContext.hasOwnProperty("isRequired")) {
                var isRequired = bindingContext.isRequired;
                var $button = $(
                    "<button class=\"button input-icon right\">" +
                    "    <i class=\"fal fa-times\"></i>" +
                    "</button>"
                );
                $element.after($button);
                ko.applyBindingsToNode($button[0], {
                    visible: !!value() && !ko.unwrap(isRequired)
                }, bindingContext);
            }
        }

        if (ko.isObservable(max)) {
            subscriptions.push(max.subscribe(function (value) {
                value = value || new Date(2099, 12, 31, 0, 0, 0, 0);

                var date = picker.selectedDates.length > 0 ? picker.selectedDates[0] : null;
                if (date && date.isAfter(value)) {
                    picker.setDate(value, true);
                }

                picker.set("maxDate", value);
            }));
        }
        if (ko.unwrap(max)) {
            picker.set("maxDate", ko.unwrap(max));
        }

        if (ko.isObservable(min)) {
            subscriptions.push(min.subscribe(function (value) {
                value = value || new Date(1900, 1, 1, 0, 0, 0, 0);

                var date = picker.selectedDates.length > 0 ? picker.selectedDates[0] : null;
                if (!date && offset && offset.from === "minDate") {
                    picker.setDate($.plusDays(value, offset.days), true);
                } else if (date && date.isBefore(value)) {
                    picker.setDate(value, true);
                }

                picker.set("minDate", value);
            }));
        }
        if (ko.unwrap(min)) {
            picker.set("minDate", ko.unwrap(min));
        }

        ko.utils.domNodeDisposal.addDisposeCallback($element[0], function () {
            for (var i = 0; i < subscriptions.length; i++) {
                subscriptions[i].dispose();
            }

            picker.destroy();
        });

        return picker;
    }

    function dateRangePicker(element, args, allBindings, bindingContext) {
        args.mode = "range";

        var max = args.max || new Date(2099, 12, 31);
        var min = args.min || new Date(1970, 1, 1);
        var maxRange = args.maxRange;
        args.max = max;
        args.min = min;

        var value = args.value;
        var onOpen = args.onOpen ? [args.onOpen] : [];
        var onChange = args.onChange ? [args.onChange] : [];
        if (ko.isObservable(value)) {
            onOpen.push(function () {
                var existingMax = ko.unwrap(max);
                var existingMin = ko.unwrap(min);

                if (this.config.maxDate && !this.config.maxDate.isEqualTo(existingMax)) {
                    this.set("maxDate", existingMax);
                }
                if (this.config.minDate && !this.config.minDate.isEqualTo(existingMin)) {
                    this.set("minDate", existingMin);
                }
            });

            onChange.push(function (selectedDates) {
                var existingMax = ko.unwrap(max);
                var existingMin = ko.unwrap(min);

                var currentMax = this.config.maxDate;
                var currentMin = this.config.minDate;

                if (selectedDates.length === 0 && currentMax && currentMax.isBefore(existingMax)) {
                    this.set("maxDate", existingMax);
                }
                if (selectedDates.length === 0 && currentMin && currentMin.isAfter(existingMin)) {
                    this.set("minDate", existingMin);
                }

                if (selectedDates.length === 1) {
                    var maxRangeDays = ko.unwrap(maxRange);
                    if ($.defined(maxRangeDays) && maxRangeDays > 0) {
                        this.set("maxDate", Date.min(existingMax, selectedDates[0].addDays(maxRangeDays - 1)));
                        this.set("minDate", Date.max(existingMin, selectedDates[0]));
                    }
                }

                value(selectedDates);
            });
        }
        args.onOpen = onOpen;
        args.onChange = onChange;
        args = parseArguments(args);

        datePicker(element, args, allBindings, bindingContext);
    }

    function _update(element, valueAccessor, allBindings) {
        var enable = true;
        if (allBindings.has("enable")) {
            if (ko.isObservable(allBindings().enable)) {
                enable = ko.unwrap(allBindings().enable);
                allBindings().enable.subscribe(function (value) {
                    toggleEnabled(element, value);
                });
            } else {
                enable = allBindings.get("enable");
            }
        }
        if (allBindings.has("disable")) {
            if (ko.isObservable(allBindings().disable)) {
                enable = !ko.unwrap(allBindings().disable);
            } else {
                enable = !allBindings.get("disable");
            }
        }

        toggleEnabled(element, enable);
    }

    function toggleEnabled(element, enable) {
        if (enable) {
            $(element).parent().find(".flatpickr-input").each(function (index, input) {
                input.removeAttribute("disabled");
            });
        } else {
            $(element).parent().find(".flatpickr-input").each(function (index, input) {
                input.setAttribute("disabled", "");
            });
        }
    }

    function parseArguments(args) {
        if ($.type(args) !== "object") {
            args = {
                value: args
            };
        }

        args.defaultDate = ko.unwrap(args.value);
        if (!args.dateFormat) {
            var dateFormat = $.getDateFormatForLocale();
            if (args.enableTime) {
                dateFormat += " " + $.getTimeFormatForLocale();
            }

            args.dateFormat = flatpickrify(dateFormat);
        }

        return args;
    }

    function parseDate(picker, date, dateFormat) {
        return date ? picker.formatDate(date, dateFormat) : "";
    }

    function flatpickrify(dateFormat) {
        var index = 0;
        var replacements = {};
        FLATPICKR_TOKEN_MAPS.forEach(function (tokenMap) {
            var token;
            for (token in tokenMap) {
                if (!tokenMap.hasOwnProperty(token)) {
                    continue;
                }

                var regex = new RegExp("\\b" + token + "\\b");
                if (regex.test(dateFormat)) {
                    var key = "{{" + index++ + "}}";
                    replacements[key] = tokenMap[token];
                    dateFormat = dateFormat.replace(regex, key);
                    break;
                }
            }
        });

        for (var key in replacements) {
            if (replacements.hasOwnProperty(key)) {
                dateFormat = dateFormat.replace(key, replacements[key]);
            }
        }

        return dateFormat;
    }

    var FLATPICKR_TOKEN_MAPS = [{
        "dddd": "l",
        "ddd": "D",
        "dd": "d",
        "d": "j"
    }, {
        "MMMM": "F",
        "MMM": "M",
        "MM": "m",
        "M": "n"
    }, {
        "fff": "",
        "ff": "",
        "f": ""
    }, {
        "HH": "H",
        "H": "H",
        "hh": "h",
        "h": "h"
    }, {
        "mm": "i",
        "m": "i"
    }, {
        "ss": "S",
        "s": "s"
    }, {
        "tt": "K"
    }, {
        "yyyy": "Y",
        "yy": "y"
    }, {
        "zzz": ""
    }];

    Flatpickr.l10ns.default.weekdays.longhand = [
        jsMessages("day-of-week.sunday"),
        jsMessages("day-of-week.monday"),
        jsMessages("day-of-week.tuesday"),
        jsMessages("day-of-week.wednesday"),
        jsMessages("day-of-week.thursday"),
        jsMessages("day-of-week.friday"),
        jsMessages("day-of-week.saturday")
    ];

    Flatpickr.l10ns.default.weekdays.shorthand = [
        jsMessages("day-of-week.sunday.abbreviation"),
        jsMessages("day-of-week.monday.abbreviation"),
        jsMessages("day-of-week.tuesday.abbreviation"),
        jsMessages("day-of-week.wednesday.abbreviation"),
        jsMessages("day-of-week.thursday.abbreviation"),
        jsMessages("day-of-week.friday.abbreviation"),
        jsMessages("day-of-week.saturday.abbreviation")
    ];

    Flatpickr.l10ns.default.months.longhand = [
        jsMessages("month.january"),
        jsMessages("month.february"),
        jsMessages("month.march"),
        jsMessages("month.april"),
        jsMessages("month.may"),
        jsMessages("month.june"),
        jsMessages("month.july"),
        jsMessages("month.august"),
        jsMessages("month.september"),
        jsMessages("month.october"),
        jsMessages("month.november"),
        jsMessages("month.december")
    ];

    Flatpickr.l10ns.default.months.shorthand = [
        jsMessages("month.january.abbreviation"),
        jsMessages("month.february.abbreviation"),
        jsMessages("month.march.abbreviation"),
        jsMessages("month.april.abbreviation"),
        jsMessages("month.may.abbreviation"),
        jsMessages("month.june.abbreviation"),
        jsMessages("month.july.abbreviation"),
        jsMessages("month.august.abbreviation"),
        jsMessages("month.september.abbreviation"),
        jsMessages("month.october.abbreviation"),
        jsMessages("month.november.abbreviation"),
        jsMessages("month.december.abbreviation")
    ];
});

