define('common/extensions/koDateExtensions',["jquery", "knockout"], function ($, ko) {
    ko.extenders.formatAsDate = function (target, options) {
        if ($.type(target()) === "string") {
            target($.stringToDate(target()));
        }

        var result = ko.pureComputed({
            read: target,
            write: function (newValue) {
                if ($.type(newValue) === "string") {
                    newValue = $.stringToDate(newValue);
                } else if (!$.defined(newValue)) {
                    if ($.type(options) === "object" && ko.unwrap(options.notNull)) {
                        newValue = target();
                    } else {
                        newValue = null;
                    }
                } else if ($.type(newValue) !== "date") {
                    throw new Error("Invalid value for date observable: " + newValue);
                }

                target(newValue);
            }
        });

        result.toDateString = function () {
            return result() ? result().toDateString() : null;
        };

        result.toLocaleDateString = function () {
            return result() ? result().toLocaleDateString() : null;
        };

        return result;
    };

    ko.extenders.formatAsDateTime = function (target, options) {
        if ($.type(target()) === "string") {
            target($.stringToDateTime(target()));
        }

        var result = ko.pureComputed({
            read: target,
            write: function (newValue) {
                if ($.type(newValue) === "string") {
                    newValue = $.stringToDateTime(newValue);
                } else if (!$.defined(newValue)) {
                    newValue = null;
                } else if ($.type(newValue) !== "date") {
                    throw new Error("Invalid value for date observable: " + newValue);
                }

                target(newValue);
            }
        });

        result.toDateTimeString = function () {
            return result() ? result().toDateTimeString() : null;
        };

        result.toLocaleDateTimeString = function () {
            return result() ? result().toLocaleDateTimeString() : null;
        };

        return result;
    };
});

