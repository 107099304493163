define('common/extensions/koSortableHeaderBinding',["jquery", "knockout"], function ($, ko) {
    "use strict";

    ko.bindingHandlers.sortableHeader = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var $element = $(element);

            var options = valueAccessor();

            var value = options.value;
            var field = options.field;
            var invert = options.invert;
            var isNumeric = !!options.isNumeric;

            $element.addClass("sortable-header");

            var iconType = (isNumeric ? "numeric" : "alpha");
            var originalIcon = iconType + "-" + (ko.unwrap(value) === field ? getDirection(invert) : "down");
            var $icon = $('<i class="sortable-header-icon color-aqua fal fa-sort-' + originalIcon + '"></i>');
            $element.append($icon);

            if (value() !== field) {
                $icon.addClass("not-active");
            }

            $element.on("click.sortable-header", function () {
                if (value() !== field) {
                    if (ko.isObservable(invert)) {
                        invert(false);
                    }

                    value(field);
                } else {
                    if (ko.isObservable(invert)) {
                        invert(!invert());
                    }
                }
            });

            var valueSub = value.subscribe(function (newValue) {
                $icon.removeClass("not-active fa-sort-" + iconType + "-down fa-sort-" + iconType + "-up");
                if (newValue === field) {
                    $icon.addClass("fa-sort-" + iconType + "-" + getDirection(invert));
                } else {
                    $icon.addClass("fa-sort-" + iconType + "-down not-active");
                }
            });

            var invertSub = null;
            if (ko.isObservable(invert)) {
                invertSub = invert.subscribe(function (newValue) {
                    if (value() === field) {
                        $icon.removeClass("fa-sort-" + iconType + "-" + (newValue ? "down" : "up"));
                        $icon.addClass("fa-sort-" + iconType + "-" + (newValue ? "up" : "down"));
                    }
                });
            }

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                valueSub.dispose();
                if (invertSub != null) {
                    invertSub.dispose();
                }
            });
        }
    };

    function getDirection(invert) {
        return invert && ko.unwrap(invert) ? "up" : "down";
    }
});

