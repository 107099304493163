define('common/widgets/pagination',["jquery", "knockout", "common/extensions/jqExtensions"], function ($, ko) {
    "use strict";

    var LOWEST_MAX_ROWS = 5;
    var HIGHEST_MAX_ROWS = 50;
    var DEFAULT_MAX_ROWS = 10;
    var DEFAULT_MAX_PAGES = 5;

    function Pagination(array, maxRows, maxPagesToShow) {
        var that = this;

        that._array = array;

        that.page = ko.observable(0);

        that._maxRows = ko.isObservable(maxRows) ? maxRows : ko.observable(maxRows);
        that.maxRows = ko.pureComputed(_maxRowsComputed.bind(that));
        that.maxRowOptions = ko.pureComputed(_maxRowOptionsComputed.bind(that));
        that.maxPagesToShow = maxPagesToShow || DEFAULT_MAX_PAGES;

        that.pageCount = ko.pureComputed(_pageCountComputed.bind(that));
        that.availablePages = ko.computed(_availablePagesComputed.bind(that));

        that._minRowIndex = ko.pureComputed(_minRowIndexComputed.bind(that));
        that._maxRowIndex = ko.pureComputed(_maxRowIndexComputed.bind(that));

        that._selectedItemIndex = ko.observable();
        that.selectedItem = ko.pureComputed(_selectedItemComputed.bind(that));

        if (ko.isObservable(that._array)) {
            that._arraySub = that._array.subscribe(function () {
                var selectedItem = that.selectedItem();
                var selectedIndex = selectedItem ? that._indexOf(selectedItem) : 0;

                if (selectedIndex < 0) {
                    that.page(0);
                    that.clearSelection();
                } else {
                    that.page(that._pageOf(selectedIndex));
                    that._selectedItemIndex(selectedIndex);
                }
            });
        }
    }

    Pagination.prototype.view = function () {
        var that = this;

        return ko.pureComputed(function () {
            var maxRows = that.maxRows();
            return ko.unwrap(that._array).slice(that._minRowIndex(), that._maxRowIndex());
        });
    };

    Pagination.prototype.setPage = function (pageNumber) {
        var that = this;

        if (pageNumber < 0) {
            pageNumber = 0;
        } else if (pageNumber >= that.pageCount()) {
            pageNumber = that.pageCount() - 1;
        }

        that.page(pageNumber);
    };

    Pagination.prototype.nextPage = function (pageNumber) {
        var that = this;

        that.setPage(that.page() + 1);
    };

    Pagination.prototype.lastPage = function () {
        var that = this;

        that.setPage(that.pageCount() - 1);
    };

    Pagination.prototype.previousPage = function (pageNumber) {
        var that = this;

        that.setPage(that.page() - 1);
    };

    Pagination.prototype.firstPage = function () {
        var that = this;

        that.setPage(0);
    };

    Pagination.prototype.selectFirst = function () {
        var that = this;

        that.select(0);
    };

    Pagination.prototype.selectNext = function () {
        var that = this;

        if ($.defined(that._selectedItemIndex())) {
            that.select(that._selectedItemIndex() + 1);
        } else {
            that.select(0);
        }
    };

    Pagination.prototype.getNext = function () {
        var that = this;

        if ($.defined(that._selectedItemIndex())) {
            return that.get(that._selectedItemIndex() + 1);
        } else {
            return undefined;
        }
    };

    Pagination.prototype.selectPrevious = function () {
        var that = this;

        if ($.defined(that._selectedItemIndex())) {
            that.select(that._selectedItemIndex() - 1);
        } else {
            that.select(0);
        }
    };

    Pagination.prototype.select = function (itemOrIndex) {
        var that = this;

        var index = that._indexOf(itemOrIndex);
        if (!$.isNumeric(index) || index < 0 || index > that.rowCount()) {
            that.page(0);
            that._selectedItemIndex(null);
        } else {
            that.page(that._pageOf(index));
            that._selectedItemIndex(index);
        }
    };

    Pagination.prototype.get = function (itemOrIndex) {
        var that = this;

        var index = that._indexOf(itemOrIndex);
        if (!$.isNumeric(index) || index < 0 || index > that.rowCount()) {
            return null;
        } else {
            return ko.unwrap(that._array)[index];
        }
    };

    Pagination.prototype.clearSelection = function () {
        var that = this;

        that._selectedItemIndex(null);
    };

    Pagination.prototype.rowCount = function () {
        var that = this;

        return ko.unwrap(that._array).length;
    };

    Pagination.prototype._pageOf = function (itemOrIndex) {
        var that = this;

        return Math.floor(that._indexOf(itemOrIndex) / that.maxRows());
    };

    Pagination.prototype._indexOf = function (itemOrIndex) {
        var that = this;

        if (!$.defined(itemOrIndex)) {
            return null;
        }

        return $.isNumeric(itemOrIndex) ? itemOrIndex : ko.unwrap(that._array).indexOf(itemOrIndex);
    };

    Pagination.prototype._selectMaxRowOption = function (maxRowOption) {
        var that = this;

        that._maxRows(maxRowOption);
    };

    Pagination.prototype.dispose = function () {
        var that = this;

        that._arraySub.dispose();
        that.availablePages.dispose();
    };

    function _maxRowsComputed() {
        var that = this;

        return Math.max(parseInt(that._maxRows() || DEFAULT_MAX_ROWS), LOWEST_MAX_ROWS);
    }

    function _maxRowOptionsComputed() {
        var that = this;

        var options = [];
        for (var i = LOWEST_MAX_ROWS; i <= HIGHEST_MAX_ROWS; i += 10) {
            options.push(i);
            if (i >= that.rowCount()) {
                break;
            }
        }

        return options;
    }

    function _pageCountComputed() {
        var that = this;

        return Math.ceil(that.rowCount() / that.maxRows());
    }

    function _availablePagesComputed() {
        var that = this;

        var maxPages = ko.unwrap(that.maxPagesToShow);
        var pageNumbers = [];

        if (that.pageCount() <= that.page()) {
            that.page(0);
        }

        if (maxPages >= that.pageCount()) {
            for (var i = 0; i < that.pageCount(); i++) {
                pageNumbers.push(i + 1);
            }

            return pageNumbers;
        }

        var minPage = that.page() - Math.floor(maxPages / 2);
        if (minPage <= 0) {
            minPage = 0;
        } else if (minPage > that.pageCount() - maxPages) {
            minPage = that.pageCount() - maxPages;
        }

        var maxPage = minPage + maxPages;
        if (maxPage > that.pageCount()) {
            maxPage = that.pageCount();
        }

        var pageNumbers = [];
        for (var i = minPage; i < maxPage; i++) {
            pageNumbers.push(i + 1);
        }

        return pageNumbers;
    }

    function _minRowIndexComputed() {
        var that = this;

        return that.page() * that.maxRows();
    }

    function _maxRowIndexComputed() {
        var that = this;

        var minRow = that._minRowIndex();
        var maxRows = that.maxRows();

        return Math.min(minRow + maxRows, that.rowCount());
    }

    function _selectedItemComputed() {
        var that = this;

        var index = that._selectedItemIndex();
        var array = ko.unwrap(that._array);

        if (!$.isNumeric(index) || index < 0 || index > array.length) {
            return null;
        }

        return array[index];
    }

    return Pagination;
});

