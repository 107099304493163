define('common/search/sourceSearcher',["jquery", "knockout", "js-routes", "common/search/searcher"], function ($, ko, jsRoutes, Searcher) {
    "use strict";

    function SourceSearcher(options) {
        var that = this;

        that.restrictToSupported = ko.observable(options && !!options.restrictToSupported);

        Searcher.call(that, "source", options);

        return that;
    }

    SourceSearcher.ROUTE = jsRoutes.controllers.common.search.SourceSearch;

    SourceSearcher.prototype = Object.create(Searcher.prototype);

    SourceSearcher.prototype._initRoute = function () {
        var that = this;

        return SourceSearcher.ROUTE.initialize(that.restrictToSupported());
    };

    SourceSearcher.prototype._searchRoute = function () {
        var that = this;

        var searchTerm = that.searchTerm();
        if (!searchTerm) {
            return null;
        }

        var restrict = that.restrictToSupported();

        return SourceSearcher.ROUTE.search(searchTerm, restrict);
    };

    return SourceSearcher;
});

