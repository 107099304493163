define('common/currency',["jquery", "knockout", "decimal", "js-currencies"], function ($, ko, Decimal, jsCurrencies) {
    "use strict";

    var leadingZeros = new RegExp("^0+");
    var leadingDecimal = new RegExp("^\\.");
    var monetaryZero = new RegExp("^0*\\.?0*$");
    var extraDots = new RegExp("(\\...).*$");

    function parseCurrency(value, currency) {
        currency = _readCurrencyParam(currency);

        if (value instanceof Decimal) {
            value = value.toString();
        }

        var type = $.type(value);

        if (!isCurrencyString(value)) {
            return "";
        }

        if (currency) {
            var symbolToRemove = currency.symbol.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            value = value.replace(new RegExp("^" + symbolToRemove), "").trim();
        }

        if (!$.isNumeric(value)) {
            value = value.replace(/[^\d\.]/gi, "").replace(/\.(?![^\.]*$)/gi, "");
        }

        if (!$.isNumeric(value)) {
            return "";
        }

        value = value.replace(extraDots, "$1").replace(leadingZeros, "").replace(leadingDecimal, "0.");
        if (value.indexOf(".") < 0) {
            value += ".";
        }

        while (value.length - value.indexOf(".") < 3) {
            value += "0";
        }

        value = value.replace(leadingDecimal, "0.");

        return value;
    }

    var taxRegExps = [{
        regex: leadingZeros,
        replace: ""
    }, {
        regex: new RegExp("^\\."),
        replace: "0."
    }, {
        regex: new RegExp("\\.0*$"),
        replace: ""
    }, {
        regex: new RegExp("(\\.\\d*[^0])0+$"),
        replace: "$1"
    }];

    function formatTax(tax) {
        if ($.type(tax) !== "string") {
            return tax;
        }

        for (var i = 0; i < taxRegExps.length; i++) {
            tax = tax.replace(taxRegExps[i].regex, taxRegExps[i].replace);
        }

        return tax;
    }

    // TODO: Precompile more of these Regular Expressions since we're going to be using them repeatedly

    var trailingPercent = new RegExp("%$");
    var decimalToMoveForward = new RegExp("(..)\\.");
    var decimalToMoveBackward = new RegExp("\\.(..)");

    function parseTax(tax, toPercent) {
        if (tax instanceof Decimal) {
            tax = tax.toString();
        }

        if (!isCurrencyString(tax)) {
            return "";
        }

        tax = tax.replace(trailingPercent, "").trim();

        if (!$.isNumeric(tax)) {
            tax = tax.replace(/[^\d\.]/gi, "").replace(/\.(?![^\.]*$)/gi, "");
        }

        if (!$.isNumeric(tax)) {
            return "";
        }

        var decimalIndex = tax.indexOf(".");
        if (decimalIndex < 0) {
            while (tax.length < 3) {
                tax = "0" + tax;
            }
            tax += ".";
        } else {
            while (decimalIndex++ <= 2) {
                tax = "0" + tax;
            }
        }

        tax = formatTax(toPercent ? tax : tax.replace(decimalToMoveForward, ".$1"));

        return tax;
    }

    function taxFormatter(value, owner, canBeEmpty) {
        var initialValue = value();

        value(formatTax(initialValue));
        initialValue = value();

        return ko.computed({
            owner: owner,
            read: function () {
                return toPercent(value());
            },
            write: function (newTax) {
                var oldTax = value();
                newTax = parseTax(newTax);
                value(undefined);
                if ($.type(newTax) === "undefined" || $.type(newTax) === "null") {
                    value(oldTax);
                } else if ($.type(newTax) === "string" && newTax.length <= 0 && !canBeEmpty) {
                    value(initialValue);
                } else {
                    value(newTax);
                }
            }
        }).extend({
            notify: "always"
        });
    }

    function asCurrency(value, currency) {
        currency = _readCurrencyParam(currency);

        if (value instanceof Decimal) {
            value = value.toString();
        }

        if (!$.isNumeric(value)) {
            return "";
        }

        var parsed = parseCurrency(value);
        if (!$.isNumeric(parsed)) {
            return "";
        }

        return (currency ? currency.symbol : "") + parsed;
    }

    function asTax(tax) {
        var decimalIndex, difference;
        if (!tax || !isCurrencyString(tax)) {
            return "";
        }

        if ((decimalIndex = tax.indexOf(".")) < 0) {
            tax += ".0";
            decimalIndex = tax.indexOf(".");
        }

        difference = tax.length - decimalIndex;
        while (difference++ < 4) {
            tax += "0";
        }

        return formatTax(tax.replace(decimalToMoveBackward, "$1.")) + "%";
    }

    function isCurrencyString(value) {
        var type = $.type(value);

        if (type !== "string") {
            if (type === "number") {
                console.error("Monetary value (" + value + ") is not a string, it can't be stored accurately");
            }
            return false;
        }

        return true;
    }

    function _readCurrencyParam(currency) {
        if (!currency) {
            return jsCurrencies["USD"];
        } else if (currency === "NONE") {
            return null;
        } else if ($.type(currency) === "string") {
            return jsCurrencies[currency.toUpperCase()];
        } else {
            return currency;
        }
    }

    return {
        asCurrency: asCurrency,
        asTax: asTax,
        parseCurrency: parseCurrency,
        parseTax: parseTax
    }
});

