define('common/utils/binarySorter',["jquery", "knockout"], function ($, ko) {
    "use strict";

    function BinarySorter(options) {
        var that = this;

        if ($.type(options) === "string" && arguments.length === 1) {
            options = {
                sortBy: [options]
            };
        }

        options = options || {};

        that.searchIndex = null;

        that.sortBy = options.sortBy;
        if (that.sortBy && $.type(that.sortBy) !== "array") {
            that.sortBy = [that.sortBy];
        }

        if (that.sortBy && that.sortBy.length > 0) {
            that._sortByOptions = [];

            for (var i = 0; i < that.sortBy.length; i++) {
                var sortOptions = {};
                if ($.type(that.sortBy[i]) === "object") {
                    var name = that.sortBy[i].name;
                    delete that.sortBy[i].name;
                    sortOptions = parseSortOptions(that.sortBy[i]);
                    that.sortBy[i] = name;
                }
                that._sortByOptions.push(sortOptions);
            }
        }

        that._globalSortOptions = $.extend(true, {
            allowDuplicatesOnInsert: false,
            ascending: true,
            nullsLast: true,
            useNumericComparisons: false,
            useCaseSensitiveComparisons: false
        }, parseSortOptions(options));
    }

    function parseSortOptions(options) {
        var output = {};

        if (options.hasOwnProperty("allowDuplicates")) {
            output.allowDuplicatesOnInsert = !!options.allowDuplicates;
        } else if (options.hasOwnProperty("ignoreDuplicates")) {
            output.allowDuplicatesOnInsert = !options.ignoreDuplicates;
        }

        if (options.hasOwnProperty("ascending")) {
            output.ascending = !!options.ascending;
        } else if (options.hasOwnProperty("descending")) {
            output.ascending = !options.descending;
        }

        if (options.hasOwnProperty("nullsLast")) {
            output.nullsLast = !!options.nullsLast;
        } else if (options.hasOwnProperty("nullsFirst")) {
            output.nullsLast = !options.nullsFirst;
        }

        if (options.hasOwnProperty("useNumericComparisons")) {
            output.useNumericComparisons = !!options.useNumericComparisons;
        } else if (options.hasOwnProperty("useStringComparisons")) {
            output.useNumericComparisons = !options.useStringComparisons;
        }

        if (options.hasOwnProperty("useCaseSensitiveComparisons")) {
            output.userCaseSensitiveComparisons = !!options.userCaseSensitiveComparisons;
        } else if (options.hasOwnProperty("useCaseInsensitiveComparisons")) {
            output.userCaseSensitiveComparisons = !options.userCaseInsensitiveComparisons;
        }

        return output;
    }

    BinarySorter.prototype._compare = function (a, b) {
        var that = this;
        if (!that.sortBy || that.sortBy.length === 0) {
            return _compareInternal(ko.unwrap(a), ko.unwrap(b), that._globalSortOptions);
        }

        var compare = 0;
        for (var i = 0; i < that.sortBy.length && compare === 0; i++) {
            var sortOptions = $.extend(true, {}, that._globalSortOptions, that._sortByOptions[i]);
            compare = _compareInternal(ko.unwrap(a[that.sortBy[i]]), ko.unwrap(b[that.sortBy[i]]), sortOptions);
        }

        return compare;
    };

    function _compareInternal(a, b, sortOptions) {
        if (!$.defined(a) && !$.defined(b)) {
            return 0;
        } else if (!$.defined(a)) {
            return sortOptions.nullsLast ? 1 : -1;
        } else if (!$.defined(b)) {
            return sortOptions.nullsLast ? -1 : 1;
        }

        if (sortOptions.useNumericComparisons && $.isNumeric(a) && $.isNumeric(b)) {
            a = parseFloat(a);
            b = parseFloat(b);
        } else if ($.type(a) === "date" && $.type(b) === "date") {
            a = a.getTime();
            b = b.getTime();
        }

        if (!sortOptions.useCaseSensitiveComparisons && !sortOptions.useNumericComparisons) {
            a = $.type(a) === "string" ? a.toLocaleLowerCase() : a;
            b = $.type(b) === "string" ? b.toLocaleLowerCase() : b;
        }

        if (a < b) {
            return sortOptions.ascending ? -1 : 1;
        } else if (a > b) {
            return sortOptions.ascending ? 1 : -1;
        } else {
            return 0;
        }
    }

    BinarySorter.prototype.indexOf = function (item, array) {
        var that = this, start, end;
        array = ko.unwrap(array);

        item = ko.unwrap(item);

        start = 0;
        end = array.length;
        that.searchIndex = Math.floor((start + end) / 2);

        while (start < end) {
            var compareResult = that._compare(item, array[that.searchIndex]);

            if (compareResult > 0) {
                start = that.searchIndex + 1;
            } else if (compareResult < 0) {
                end = that.searchIndex;
            } else {
                return that.searchIndex;
            }

            that.searchIndex = Math.floor((start + end) / 2);
        }

        return -1;
    };

    BinarySorter.prototype.indexFor = function (item, array) {
        var index = this.indexOf(item, array);

        if (index >= 0 && !this._globalSortOptions.allowDuplicatesOnInsert) {
            return -1;
        }

        return this.searchIndex;
    };

    BinarySorter.prototype.insert = function (item, array) {
        var index = this.indexFor(item, array);

        if (index >= 0) {
            array.splice(index, 0, item);
        }

        return index >= 0 ? true : false;
    };

    return BinarySorter;
});

