define('common/search/countrySearcher',["jquery", "knockout", "js-routes", "common/search/searcher"], function ($, ko, jsRoutes, Searcher) {
    "use strict";

    function CountrySearcher(options) {
        options = options || {};

        var that = Searcher.call(this, "country", options);

        that.whitelist = ko.observableArray();
        that.blacklist = ko.observableArray();

        that._readOptions(options);
    }

    CountrySearcher.route = jsRoutes.controllers.common.search.CountrySearch;

    CountrySearcher.prototype = Object.create(Searcher.prototype);

    CountrySearcher.prototype._readOptions = function (options) {
        var that = this;

        if (options.hasOwnProperty("whitelist")) {
            that.whitelist(options.whitelist);
        }

        if (options.hasOwnProperty("blacklist")) {
            that.blacklist(options.blacklist);
        } else {
            that.blacklist(["RU"]);
        }
    };

    CountrySearcher.prototype._initRoute = function () {
        var that = this;

        return CountrySearcher.route.initialize(that.withUnderlyingUser);
    };

    CountrySearcher.prototype._searchRoute = function () {
        var that = this;

        var searchTerm = that.searchTerm();
        if (!searchTerm) {
            return null;
        }

        return CountrySearcher.route.search(that.withUnderlyingUser, searchTerm);
    };

    CountrySearcher.prototype.setResults = function (results) {
        var that = this;

        if (that._hasWhitelist() || that._hasBlacklist()) {
            results = results.filter(function (country) {
                return that.countryIsAllowed(country);
            });
        }

        Searcher.prototype.setResults.call(that, results);
    };

    CountrySearcher.prototype.countryIsAllowed = function (country) {
        var that = this;

        var countryId = $.type(country) === "object" ? country.id : country;

        if (that._hasWhitelist()) {
            return that.whitelist().indexOf(countryId) >= 0;
        } else if (that._hasBlacklist()) {
            return that.blacklist().indexOf(countryId) < 0;
        } else {
            return true;
        }
    };

    CountrySearcher.prototype._hasWhitelist = function () {
        var that = this;

        return that.whitelist() && that.whitelist().length > 0;
    };

    CountrySearcher.prototype._hasBlacklist = function () {
        var that = this;

        return that.blacklist() && that.blacklist().length > 0;
    };

    return CountrySearcher;
});

