define('common/select/distributorSelector',[
    "jquery",
    "knockout",
    "common/select/searchSelector",
    "common/search/distributorSearcher"
], function ($, ko, SearchSelector, DistributorSearcher) {
    "use strict";

    function DistributorSelector(country, options, id) {
        options = options || {};
        var searcher = new DistributorSearcher(country, options.searcher);
        var that = SearchSelector.call(this, options, id || "distributor-selector", searcher);

        that.searcher.countryId.subscribe(function (countryId) {
                    if (that.isSelected()) {
                        if (that.val() && that.val().countries.indexOf(countryId) < 0) {
                            that.clearSelection();
                        } else if (that.vals()) {
                            var removals = [];
                            that.vals().forEach(function (e) {
                                if (e && e.countries && e.countries.indexOf(countryId) < 0) {
                                    removals.push(e);
                                }
                            });
                            removals.forEach(function (e) {
                                that._deselectValue(e);
                            });
                        }
                    }
        });
    }

    DistributorSelector.prototype = Object.create(SearchSelector.prototype);

    return DistributorSelector;
});

