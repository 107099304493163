define('common/search/theaterSearcher',[
    "jquery",
    "knockout",
    "js-routes",
    "js-messages",
    "common/search/searcher",
    "common/select/circuitSelector",
    "common/select/sourceSelector",
    "common/select/distributorSelector"
], function (
    $,
    ko,
    jsRoutes,
    jsMessages,
    Searcher,
    CircuitSelector,
    SourceSelector,
    DistributorSelector
) {
    "use strict";

    function TheaterSearcher(country, id, options) {
        this.waitingOnCountryI18nKey = "theater-selector.waiting-on.country";

        options = options || {};

        var that = Searcher.call(this, "theater", options, country);

        that.id = id;

        that.circuit = new CircuitSelector(country, {}, id + "-circuit-selector");

        if (options.hasOwnProperty("withDistributorSelector")) {
            that.distributor = new DistributorSelector(country, {}, that.id + "-distributor-selector");
            that.distributor.onChange = _onSelectedDistributorChange.bind(that);
        }

        that.daysSinceLastReport = ko.observable(null);

        that.includeClosed = ko.observable(false);
        that.includeNonReporting = ko.observable(true);
        that.allowSubTheaters = ko.observable(false);
        that.includeManual = ko.observable(true);
        that.limitToDownloadableSources = ko.observable(false);
        that.unlimited = ko.observable(false);
        that.includeDomestic = ko.observable(false);

        that._linkedCircuit = null;
        that._linkedSource = null;

        that._readOptions(options);

        return that;
    }

    TheaterSearcher.prototype = Object.create(Searcher.prototype);

    TheaterSearcher.prototype._getRoute = function () {
        return jsRoutes.controllers.common.search.TheaterSearch;
    };

    TheaterSearcher.prototype.getRowCss = function (theater) {
        return !theater.isOpen ? "error-row" : "";
    };

    TheaterSearcher.prototype._readOptions = function (options) {
        var that = this;

        if (!options) {
            return;
        }

        if (options.hasOwnProperty("includeNonReporting")) {
            that.includeNonReporting(!!options.includeNonReporting);
        }

        if (options.hasOwnProperty("allowSubTheaters")) {
            that.allowSubTheaters(!!options.allowSubTheaters);
        }

        if (options.hasOwnProperty("includeManual")) {
            that.includeManual(!!options.includeManual);
        }

        if (options.hasOwnProperty("limitToDownloadableSources")) {
            that.limitToDownloadableSources(!!options.limitToDownloadableSources);
        }

        if (options.hasOwnProperty("unlimited")) {
            that.unlimited(!!options.unlimited);
        }

        if (options.hasOwnProperty("includeDomestic")) {
            that.includeDomestic(!!options.includeDomestic);
        }

        if (options.hasOwnProperty("withUnderlyingUser")) {
            that.withUnderlyingUser = !!options.withUnderlyingUser;
        }

        if (options.hasOwnProperty("linkedCircuit")) {
            that._linkedCircuit = options.linkedCircuit;

            if (that.withUnderlyingUser) {
                that._linkedCircuit.searcher.withUnderlyingUser = true;
            }

            if (ko.unwrap(that._linkedCircuit)) {
                that.circuit.selectResult(ko.unwrap(that._linkedCircuit));
            }

            if (ko.isObservable(that._linkedCircuit)) {
                that._linkedCircuit.subscribe(function (circuit) {
                    that.circuit.selectResult(circuit);
                });
            }
        }

        if (options.hasOwnProperty("linkedSource")) {
            that._linkedSource = options.linkedSource;

            if (that.withUnderlyingUser) {
                that._linkedSource.searcher.withUnderlyingUser = true;
            }

            that.source = new SourceSelector({}, that.id + "-source-selector");
            that.source.onChange = _onSelectedSourceChange.bind(that);

            if (ko.unwrap(that._linkedSource)) {
                that.source.selectResult(ko.unwrap(that._linkedSource));
            }

            if (ko.isObservable(that._linkedSource)) {
                that._linkedSource.subscribe(function (source) {
                    that.source.selectResult(source);
                });
            }
        }
    };

    TheaterSearcher.prototype._initRoute = function () {
        var that = this;

        return that._getRoute().initialize(!!that.withUnderlyingUser);
    };

    TheaterSearcher.prototype._searchRoute = function () {
        var that = this;

        var route = that._getRoute();

        var withUnderlyingUser = that.withUnderlyingUser;
        var term = that.searchTerm();
        var circuitId = that.circuit.isSelected() ? that.circuit.val().id : null;
        var countryId = that.countryId();
        var sourceId = that.source && that.source.isSelected() ? that.source.val().sourceId : null;
        var distributorId = that.distributor && that.distributor.isSelected() ? that.distributor.val().id : null;
        var daysSinceLastReport = that.daysSinceLastReport();
        var includeClosed = !!that.includeClosed();
        var includeNonReporting = !!that.includeNonReporting();
        var allowSubTheaters = that.allowSubTheaters();
        var includeManual = !!that.includeManual();
        var limitToDownloadableSources = !!that.limitToDownloadableSources();
        var unlimited = that.unlimited();
        var includeDomestic = that.includeDomestic();

        if (!countryId || (!unlimited && !term && !circuitId)) {
            return null;
        }

        return route.search(
            withUnderlyingUser,
            term,
            circuitId,
            countryId,
            sourceId,
            distributorId,
            daysSinceLastReport,
            includeClosed,
            includeNonReporting,
            allowSubTheaters,
            includeManual,
            limitToDownloadableSources,
            unlimited,
            includeDomestic
        );
    };

    function _onSelectedSourceChange(source) {
        var that = this;

        if (!source) {
            return;
        }

        that._results([]);
    }

    function _onSelectedDistributorChange(distributor) {
        var that = this;

        if (!distributor) {
            return;
        }

        that._results([]);
    }

    return TheaterSearcher;
});

