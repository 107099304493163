define('common/extensions/jqCleanWhitespacePlugin',["jquery"], function ($) {
    "use strict";

    $.fn.cleanWhitespace = function () {
        var textNodes = this.contents().filter(function () {
            return (this.nodeType == 3 && !/\S/.test(this.nodeValue));
        }).remove();

        return this;
    };
});

