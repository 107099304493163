define('common/boxOfficeReports',[
    "jquery",
    "knockout",
    "js-routes",
    "toastr",
    "js-messages",
    "common/storage",
    "common/widgets/navBar",
    "common/widgets/spinner",
    "common/extensions/jqExtensions"
], function (
    $,
    ko,
    jsRoutes,
    Toastr,
    jsMessages,
    Storage,
    NavBar,
    Loader
) {
    "use strict";

    function BoxOfficeReports() {
        var that = this;

        that.dlManager = new NavBar("#download-manager-bar");

        that.pending = ko.observableArray();
        that.fulfilled = ko.observableArray();
        that.failed = ko.observableArray();

        that.failedToRetrieveReports = ko.observable(false);

        that._timesteps = new Schedule(2, 4, 15);
    }

    BoxOfficeReports.ROUTE = jsRoutes.controllers.common.BoxOfficeReports;

    BoxOfficeReports.prototype.requestReport = function (
        theaterId,
        titleId,
        releaseId,
        distributorId,
        startDate,
        endDate,
        wantsEmail,
        wantsDownload,
        reportType
    ) {
        var that = this;

        var route = BoxOfficeReports.ROUTE.requestReport(
            theaterId,
            titleId,
            releaseId,
            distributorId,
            {
                startDate: startDate,
                endDate: endDate
            },
            wantsEmail,
            wantsDownload,
            reportType
        );

        Toastr.options = {
            positionClass: "toast-top-center"
        };

        $.ajaxr(route).done(function (response) {
            if (response.error) {
                Toastr.error(response.error);
            } else {
                Toastr.success(response.success);

                that.addPending(response.pending);
                if (wantsDownload) {
                    that.dlManager.open();
                }
            }
        });
    };

    BoxOfficeReports.prototype.previewReport = function (
        theaterId,
        titleId,
        releaseId,
        distributorId,
        range,
        reportType
    ) {
        $.openInTab(BoxOfficeReports.ROUTE.previewReport(
            theaterId,
            titleId,
            releaseId,
            distributorId,
            range,
            reportType
        ));
    };

    BoxOfficeReports.prototype.showPending = function () {
        var that = this;

        return !Loader.isLoading() && (
            that.pending().length > 0
            || that.fulfilled().length > 0
            || that.failed().length > 0
        );
    };

    BoxOfficeReports.prototype.pendingText = function () {
        var that = this;

        return jsMessages(that.fulfilled().length > 0 ? "generate-report.fulfilled.label" : "report.plural");
    };

    BoxOfficeReports.prototype.start = function () {
        var that = this;

        that._initialCheck();
    };

    BoxOfficeReports.prototype._initialCheck = function () {
        var that = this;

        that._timesteps.setToDefault();
        that._checkFulfilledEbors();
    };

    BoxOfficeReports.prototype._newReportCheck = function () {
        var that = this;

        that._timesteps.reset();
        that._checkFulfilledEbors();
    };

    BoxOfficeReports.prototype._checkFulfilledEbors = function () {
        var that = this;

        $.ajaxr(BoxOfficeReports.ROUTE.checkFulfilledEbors()).done(function (response) {
            if (response.error) {
                Toastr.error(reponse.error);
            } else {
                that._processReceivedPending(response.requests);
            }

            if (response && response.requests && response.requests.length > 0) {
                that._scheduleCheck();
            }
        });
    };

    BoxOfficeReports.prototype._scheduleCheck = function () {
        var that = this;

        that._clearTimeout();
        that._checkTimeout = setTimeout(that._checkFulfilledEbors.bind(that), that._timesteps.next().millis);
    };

    BoxOfficeReports.prototype._clearTimeout = function () {
        var that = this;

        if (that._checkTimeout) {
            clearTimeout(that._checkTimeout);
            that._checkTimeout = null;
        }
    };

    BoxOfficeReports.prototype.addPending = function (newPending) {
        var that = this;

        that._processReceivedPending.call(that, newPending);
        that._timesteps.reset();
        that._scheduleCheck();
    };

    BoxOfficeReports.prototype.hasPending = function () {
        var that = this;

        return that.pending().filter(function (p) {
            return !p.isReady;
        }).length > 0;
    };

    BoxOfficeReports.prototype._processReceivedPending = function (requests) {
        var that = this;

        if (!$.defined(requests)) {
            that.failedToRetrieveReports(true);
            return;
        } else {
            that.failedToRetrieveReports(false);
        }

        var newPending = [];
        var newFulfilled = [];
        var newFailed = [];

        requests.forEach(function (request) {
            formatRequestDateRange(request);

            request.film = request.title || request.release || request.distributor || null;

            if (!request.isReady) {
                if (request.failed) {
                    request.onDismiss = function () {
                        $.ajaxr(BoxOfficeReports.ROUTE.dismissFailedReport(request.id));
                        that.failed.remove(request);

                        if (newFulfilled.length <= 0 && newPending.length <= 0 && newFailed.length <= 0) {
                            that.dlManager.close();
                        }
                    };
                    newFailed.push(request);
                } else {
                    newPending.push(request);
                }
                return;
            }

            request.onClick = function () {
                $.openInTab(BoxOfficeReports.ROUTE.retrieveReport(request.id));
                that.fulfilled.remove(request);

                if (newFulfilled.length <= 0 && newPending.length <= 0 && newFailed.length <= 0) {
                    that.dlManager.close();
                }
            };
            newFulfilled.push(request);
        });

        that.pending(newPending);
        that.fulfilled(newFulfilled);
        that.failed(newFailed);
    };

    function formatRequestDateRange(request) {
        if (!/ -> /.test(request.dateRange)) {
            return;
        }

        var dateRange = request.dateRange.split(" -> ");
        var startDate = $.toLocaleDate(dateRange[0]);
        var endDate = $.toLocaleDate(dateRange[1]);

        request.dateRange = startDate + " <i class='fas fa-caret-right'></i> " + endDate;
    }

    function Schedule() {
        var that = this;

        var previousStep;
        for (var i = 0; i < arguments.length; i++) {
            var currentStep = new ScheduleStep(arguments[i]);
            if (i === 0) {
                that._firstStep = currentStep;
            }

            if (previousStep) {
                previousStep.next = currentStep;
                currentStep.prev = previousStep;
            }

            that._lastStep = currentStep;
            previousStep = currentStep;
        }

        that._currentStep = that._firstStep;
    }

    Schedule.prototype.next = function () {
        var that = this;

        var currentStep = that._currentStep;
        if (that._currentStep.next) {
            that._currentStep = that._currentStep.next;
        }

        return currentStep;
    };

    Schedule.prototype.reset = function () {
        var that = this;

        that._currentStep = that._firstStep;
    };

    Schedule.prototype.setToDefault = function () {
        var that = this;

        that._currentStep = that._lastStep;
    };

    function ScheduleStep(seconds) {
        var that = this;

        that.millis = seconds * 1000;
        that.next = null;
        that.prev = null;
    }

    return new BoxOfficeReports();
});

