define('common/utils/dateTimeFormatsByLocaleId',["jquery"], function ($) {
    var dateTimeFormats = {
        "as": {},
        "as-IN": {},
        "bn": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "bn-IN": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "de": {
            "shortDateFormat": "dd.MM.yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "HH:mm"
        },
        "de-DE": {
            "shortDateFormat": "dd.MM.yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "HH:mm"
        },
        "en": {
            "shortDateFormat": "M/d/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "en-AU": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "en-CA": {
            "shortDateFormat": "yy-MM-dd",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "en-UK": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "en-US": {
            "shortDateFormat": "M/d/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "es": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "H:mm"
        },
        "es-CL": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "H:mm"
        },
        "es-ES": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "H:mm"
        },
        "es-MX": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "HH:mm"
        },
        "fr": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "HH:mm"
        },
        "fr-CA": {
            "shortDateFormat": "yy-MM-dd",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "HH 'h' mm"
        },
        "gu": {
            "shortDateFormat": "d-MM-yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "hh:mm tt"
        },
        "gu-IN": {
            "shortDateFormat": "d-MM-yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "hh:mm tt"
        },
        "kn": {
            "shortDateFormat": "yy-MM-dd",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "hh:mm tt"
        },
        "kn-IN": {
            "shortDateFormat": "yy-MM-dd",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "hh:mm tt"
        },
        "ml": {
            "shortDateFormat": "yy-MM-dd",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "ml-IN": {
            "shortDateFormat": "yy-MM-dd",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "mr": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "mr-IN": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "or": {},
        "or-IN": {},
        "pa": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "pa-IN": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "pt": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "HH:mm"
        },
        "pt-BR": {
            "shortDateFormat": "dd/MM/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "HH:mm"
        },
        "ru": {
            "shortDateFormat": "dd.MM.yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "H:mm"
        },
        "ru-RU": {
            "shortDateFormat": "dd.MM.yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "H:mm"
        },
        "ta": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "a h:mm"
        },
        "ta-IN": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1}, {0}",
            "shortTimeFormat": "a h:mm"
        },
        "te": {
            "shortDateFormat": "dd-MM-yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "te-IN": {
            "shortDateFormat": "dd-MM-yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "tr": {
            "shortDateFormat": "d.MM.yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "HH:mm"
        },
        "tr-TR": {
            "shortDateFormat": "d.MM.yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "HH:mm"
        },
        "ur": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        },
        "ur-IN": {
            "shortDateFormat": "d/M/yy",
            "shortDateTimeFormat": "{1} {0}",
            "shortTimeFormat": "h:mm tt"
        }
    };

    return {
        get: function (localeId, field) {
            var locale = dateTimeFormats[localeId];
            if ($.defined(locale)) {
                return locale[field];
            } else {
                throw new Error("Missing locale " + localeId + "!");
            }
        }
    }
});

