define('common/search/userSearcher',[
    "jquery",
    "knockout",
    "js-routes",
    "common/search/searcher",
    "common/select/countrySelector",
    "common/select/circuitSelector",
    "common/select/theaterSelector",
    "common/select/distributorSelector"
], function (
    $,
    ko,
    jsRoutes,
    Searcher,
    CountrySelector,
    CircuitSelector,
    TheaterSelector,
    DistributorSelector
) {
    "use strict";

    function UserSearcher(id, options) {
        var that = Searcher.call(this, "user", options);

        options = options || {};

        that.country = new CountrySelector({
            searcher: {
                withUnderlyingUser: !!options.withUnderlyingUser
            }
        }, id + "-country-selector");
        that.circuit = new CircuitSelector(that.country, {
            searcher: {
                withUnderlyingUser: !!options.withUnderlyingUser
            }
        }, id + "-circuit-selector");
        that.theater = new TheaterSelector(that.country, that.circuit, {
            searcher: {
                withUnderlyingUser: !!options.withUnderlyingUser
            }
        }, id + "-theater-selector");
        that.distributor = new DistributorSelector(that.country, {
            searcher: {
                withUnderlyingUser: !!options.withUnderlyingUser
            }
        }, id + "-distributor-selector");

        that.accountType = ko.observable();

        that.includeAssigned = ko.observable(false);
        that.unlimited = ko.observable(false);

        that._readOptions(options);

        return that;
    }

    UserSearcher.route = jsRoutes.controllers.common.search.UserSearch;

    UserSearcher.prototype = Object.create(Searcher.prototype);

    UserSearcher.prototype.setResults = function (results) {
        var that = this;

        results.forEach(function (result) {
            if (result) {
                result.timeCreated = $.stringToDateTime(result.timeCreated);
                result.expirationDate = ko.observable(result.expirationDate).extend({formatAsDate: true});
                result.isExpired = ko.pureComputed(function () {
                    return result.expirationDate() && !result.expirationDate().isAfter($.today());
                });
                result.lastActivity = $.stringToDateTime(result.lastActivity);
                result.lastSubmission = $.stringToDateTime(result.lastSubmission);
            }
        });

        Searcher.prototype.setResults.call(that, results);
    };

    UserSearcher.prototype._readOptions = function (options) {
        var that = this;

        if (!options) {
            return;
        }

        if (options.hasOwnProperty("includeAssigned")) {
            that.includeAssigned(!!options.includeAssigned);
        }

        if (options.hasOwnProperty("unlimited")) {
            that.unlimited(!!options.unlimited);
        }
    };

    UserSearcher.prototype._initRoute = function () {
        return UserSearcher.route.initialize();
    };

    UserSearcher.prototype._searchRoute = function () {
        var that = this;

        var searchTerm = that.searchTerm();
        var countryId = that.country.val() ? that.country.val().id : null;
        var circuitId = that.circuit.val() ? that.circuit.val().id : null;
        var theaterId = that.theater.val() ? that.theater.val().id : null;
        var distributorId = that.distributor.val() ? that.distributor.val().id : null;
        var accountType = that.accountType() ? that.accountType() : null;
        var includeAssigned = that.includeAssigned();
        var unlimited = that.unlimited();

        if (!searchTerm && !countryId && !circuitId && !theaterId && !distributorId) {
            return null;
        }

        return UserSearcher.route.search(
            searchTerm,
            countryId,
            circuitId,
            theaterId,
            distributorId,
            accountType,
            includeAssigned,
            unlimited
        );
    };

    return UserSearcher;
});

