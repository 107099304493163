define('common/select/sourceSelector',[
    "jquery",
    "knockout",
    "common/select/searchSelector",
    "common/search/sourceSearcher"
], function ($, ko, SearchSelector, SourceSearcher) {
    "use strict";

    function SourceSelector(options, id) {
        options = options || {};
        SearchSelector.call(this, options, id || "source-selector", new SourceSearcher(options.searcher));
    }

    SourceSelector.prototype = Object.create(SearchSelector.prototype);

    return SourceSelector;
});

