define('common/extensions/jqMaxWidthPlugin',["jquery"], function ($) {
    "use strict";

    $.fn.maxWidth = function (action, selector) {
        action = action || "update";
        var maxWidth = Math.max.apply(Math, this.map(function () {
            var $this = $(this);
            var $clone = $this.clone();
            $clone.css("visibility", "hidden");

            $("body").append($clone);
            var width;
            if (selector) {
                width = Math.max.apply(Math, $clone.find(selector).map(function () {
                    return $(this).outerWidth();
                }));
            } else {
                width = $clone.outerWidth();
            }
            $clone.remove();

            return width;
        }).get());

        if (action === "get") {
            return maxWidth;
        } else if (action === "update") {
            if (selector) {
                this.find(selector).css("width", maxWidth);
            } else {
                this.css("width", maxWidth);
            }
            return this;
        }
    };
});

