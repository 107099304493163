define('common/storage',["jquery", "knockout"], function ($, ko) {
    "use strict";

    function storageAvailable(type) {
        try {
            var storage = window[type];
            storage.setItem("a", "b");
            storage.removeItem("a");

            return true;
        } catch (e) {
            return false;
        }
    }

    function StorageType(type) {
        var that = this;

        that.storage = window[type];
        that._enabled = storageAvailable(type);
    }

    StorageType.prototype.enabled = function () {
        var that = this;

        return that._enabled;
    };

    StorageType.prototype.store = function (key, object) {
        var that = this;

        var finalKey = that._buildFinalKey(key);
        if (that._enabled && finalKey) {
            that.storage.setItem(finalKey, JSON.stringify(object));
        }
    };

    StorageType.prototype.retrieve = function (key) {
        var that = this;

        var finalKey = that._buildFinalKey(key);
        if (that._enabled && finalKey && that.storage.getItem(finalKey)) {
            return JSON.parse(that.storage.getItem(finalKey));
        } else {
            return null;
        }
    };

    StorageType.prototype.remove = function (key) {
        var that = this;

        var finalKey = that._buildFinalKey(key);
        var object = that._enabled && finalKey ? that.storage.getItem(finalKey) : null;

        if (object) {
            that.storage.removeItem(finalKey);
        }

        return object || null;
    };

    StorageType.prototype._buildFinalKey = function (key) {
        var username = $("#current-username").val();

        if (!username) {
            return null;
        }

        return username + "." + key;
    };

    return {
        local: new StorageType("localStorage"),
        session: new StorageType("sessionStorage")
    };
});

