define('common/widgets/spinner',["jquery", "knockout"], function ($, ko) {
    "use strict";

    var DURATION = 400;

    var PageLoad = function () {
        var that = this;

        that._loadSelector = "#loading-title";
        that._contentSelector = "#loaded-title, .page-content, .content, .content-divider, #pending-reports";

        that.isLoading = ko.observable(false);
        that._isInitialLoad = true;
        that.start();

        return that;
    };

    PageLoad.prototype.start = function (hideElements, peek) {
        var that = this;

        if (peek ? that.isLoading.peek() : that.isLoading()) {
            return;
        }

        if ($.type(hideElements) === "undefined") {
            hideElements = that._isInitialLoad;
        }

        that.isLoading(true);

        $(".swift-tooltip").remove();

        if (hideElements) {
            $(that._loadSelector).removeClass("hidden").show();
        } else {
            $(that._contentSelector).addClass("loading-fade");
            if (!that._timeout) {
                that._timeout = setTimeout(function () {
                    that._timeout = null;
                    $(that._contentSelector).fadeTo(DURATION, 0.4);
                    $(that._loadSelector).removeClass("hidden").show();
                }, 200);
            }
        }

        return that;
    };

    PageLoad.prototype.stop = function (onFinish) {
        var that = this;

        if (!that.isLoading()) {
            return;
        }

        if ($.type(onFinish) === "function") {
            that.onFinish = onFinish;
        }

        $(that._loadSelector).hide();
        var $content = $(that._contentSelector);
        if ($content.hasClass("loading-fade")) {
            if (that._timeout) {
                clearTimeout(that._timeout);
                that._timeout = null;
                that._onFinish(onFinish);
            } else {
                $content.fadeTo(DURATION, 1, that._onFinish.call(that, onFinish));
            }
        } else {
            $content.css("opacity", 0.1);
            $content.removeClass("loading-hidden");
            $content.fadeTo(DURATION, 1, onFinish);
        }

        that.isLoading(false);
        that._isInitialLoad = false;

        return that;
    };

    PageLoad.prototype._onFinish = function (onFinish) {
        var that = this;

        $(that._contentSelector).removeClass("loading-fade");
        if ($.type(onFinish) === "function") {
            onFinish.apply(this, arguments);
        }
    };

    return new PageLoad();
});

