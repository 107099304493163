define('common/extensions/koIntegerExtension',["jquery", "knockout", "decimal", "common/currency"], function ($, ko, Decimal, Currency) {
    ko.extenders.integer = function (target, options) {
        return ko.computed({
            read: target,
            write: function (value) {
                var currentValue = target();
                var defined = $.defined(value) && value !== "";
                var isInteger = $.isNumeric(value) && value == Math.floor(value);

                if (!defined || isInteger) {
                    target(value);
                } else {
                    target("TEMP")
                    target(currentValue);
                }
            }
        });
    };
});

