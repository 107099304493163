define('common/extensions/koNumberBindings',[
    "jquery",
    "knockout",
    "decimal",
    "common/currency"
], function ($, ko, Decimal, Currency) {
    "use strict";

    ko.bindingHandlers.number = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            throw new Error("AAAAAaaaaAAAAAAAaaAAaaaa");
        }
    };

    ko.bindingHandlers.currency = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var options = _parseOptions(valueAccessor);
            _setDefaultOption(options, "maxValue", 999999999);
            _setDefaultOption(options, "precision", 2);
            _setDefaultOption(options, "symbolInString", !$(element).is("input") && !$(element).is("textarea"));

            if (!options.hasOwnProperty("symbol")) {
                throw new Error("Currency binding requires symbol parameter!");
            }

            number(element, options);
        }
    };

    ko.bindingHandlers.decimal = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var options = _parseOptions(valueAccessor);
            _setDefaultOption(options, "maxValue", 999999999);
            _setDefaultOption(options, "precision", null);

            number(element, options);
        }
    };

    ko.bindingHandlers.integer = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var options = _parseOptions(valueAccessor);
            _setDefaultOption(options, "currency", null);
            _setDefaultOption(options, "maxValue", 999999999);
            _setDefaultOption(options, "precision", 0);

            number(element, options);
        }
    };

    ko.bindingHandlers.percent = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var options = _parseOptions(valueAccessor);
            _setDefaultOption(options, "decimalShift", 2);
            _setDefaultOption(options, "maxValue", 100);
            _setDefaultOption(options, "precision", 2);
            _setDefaultOption(options, "symbol", "%");
            _setDefaultOption(options, "symbolAlign", "right");
            _setDefaultOption(options, "symbolInString", !$(element).is("input") && !$(element).is("textarea"));

            number(element, options);
        }
    };

    function number(element, options) {
        var decimalValue = options.value;
        var stringValue = ko.observable();

        var canBeNegative = options.hasOwnProperty("canBeNegative") ? options.canBeNegative : undefined;
        var decimalShift = options.hasOwnProperty("decimalShift") ? options.decimalShift : null;
        var defaultValue = options.hasOwnProperty("defaultValue") ? options.defaultValue : null;
        var maxValue = options.hasOwnProperty("maxValue") ? options.maxValue : undefined;
        var nullValue = options.hasOwnProperty("nullValue") ? options.nullValue : null;
        var precision = options.hasOwnProperty("precision") ? options.precision : null;
        var symbolInString = options.hasOwnProperty("symbolInString") ? options.symbolInString : false;
        var symbol = options.hasOwnProperty("symbol") ? options.symbol : null;
        var symbolAlign = options.hasOwnProperty("symbolAlign") ? options.symbolAlign : "left";

        var writable = ko.isWritableObservable(decimalValue);

        var initialSymbol = _parseSymbol(symbol);
        if (initialSymbol && $(element).is(".input")) {
            var $element = $(element);

            if (!$element.parent().is(".input-with-icon")) {
                $element.wrap("<span class='input-with-icon'></span>");
            }
            var $inputWithIcon = $element.parent();

            var $inputIcon = $element.siblings(".input-icon." + symbolAlign);
            if ($inputIcon.length > 0) {
                $inputIcon.remove();
            }

            $inputIcon = $("<span class='input-icon'></span>");
            $inputIcon.addClass(symbolAlign);
            $inputIcon.text(initialSymbol);
            $inputIcon.insertBefore($element);

            $inputWithIcon.addClass(symbolAlign);
        }

        function read() {
            var string = stringValue();
            var decimal = ko.unwrap(decimalValue);

            if (symbolInString && !!decimal) {
                var symbolValue = _parseSymbol(symbol);
                if (symbolValue && !$(element).is(".input") && string) {
                    if (symbolAlign === "right") {
                        string += symbolValue;
                    } else {
                        string = symbolValue + string;
                    }
                }
            }

            return string;
        }

        function write(newValue) {
            var oldValue = stringValue();

            if ($.defined(newValue)) {
                if ($.type(newValue) === "number") {
                    if (newValue % 1 !== 0) {
                        throw new Error("Numeric float passed to number binding!");
                    }

                    newValue = newValue.toString();
                } else if (newValue instanceof Decimal) {
                    newValue = newValue.toFixed();
                }

                newValue = newValue.replace(/[^0-9-.]/g, '');

                if (canBeNegative) {
                    newValue = newValue.replace(/(?!^)-/g, '');
                } else {
                    newValue = newValue.replace(/-/g, '');
                }

                if (!precision) {
                    newValue = newValue.replace(/\./g, '');
                }
            }

            var string = newValue;

            var decimalString = $.isNumeric(newValue) ? new Decimal(newValue) : null;

            var decimal = decimalString ? new Decimal(decimalString) : null;

            var _maxValue = ko.unwrap(maxValue);
            if (_maxValue && $.type(_maxValue) === "string") {
                _maxValue = new Decimal(_maxValue);
            }
            if (_maxValue && decimal && decimal.greaterThan(_maxValue)) {
                if (_maxValue instanceof Decimal) {
                    decimal = _maxValue;
                } else {
                    decimal = new Decimal(_maxValue);
                }
                string = decimal.toFixed();
            }
            if (precision && precision > 0 && decimal && (!writable || (string.indexOf(".") >= 0 && string.length - string.indexOf(".") - 1 > precision))) {
                var power = Decimal.pow(10, precision);
                decimal = decimal.times(power).truncated().dividedBy(power);
                string = decimal.toFixed();

                var newDecimalIndex = string.indexOf(".");
                var zeroes;
                if (newDecimalIndex < 0) {
                    string += ".";
                    zeroes = 2;
                } else {
                    zeroes = precision - (string.length - newDecimalIndex - 1);
                }
                if (zeroes > 0) {
                    string += (new Array(zeroes + 1)).join("0");
                }
            }

            if (decimalShift && decimal) {
                decimal = decimal.dividedBy(Decimal.pow(10, decimalShift));
            }

            stringValue(string || nullValue);
            if (!string || string === oldValue) {
                stringValue.notifySubscribers();
            }

            if (!writable) {
                return;
            }

            decimalValue.__isInternalWrite = true;
            decimalValue(decimal);
            decimalValue.__isInternalWrite = false;

            if (stringValue() && !decimalValue()) {
                var regex = canBeNegative ? /^-?.?$/ : /^.?$/;
                if (!regex.test(stringValue())) {
                    $(element).parent().addClass("contents-invalid");
                }
            } else {
                $(element).parent().removeClass("contents-invalid");
            }
        }

        var initialValue = _shiftDecimal(ko.unwrap(decimalValue), decimalShift);
        write($.defined(initialValue) ? initialValue : defaultValue);

        var decimalSubscription;
        if (ko.isObservable(decimalValue)) {
            decimalSubscription = decimalValue.subscribe(function (value) {
                if (decimalValue.__isInternalWrite) {
                    return;
                }

                write(value ? _shiftDecimal(value, decimalShift) : value);
            });
            decimalValue.clear = function () {
                formatted(null);
            };
        }

        var formatted = ko.computed(writable ? {
            read: read,
            write: write
        } : read).extend({
            notify: "always"
        });

        var bindings = {};
        var binding;
        if ($(element).is('input[type="text"],input:not([type])')) {
            binding = "textInput";
        } else {
            binding = "text";
        }
        bindings[binding] = formatted;

        ko.applyBindingsToNode(element, bindings);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            formatted.dispose();
        });
    }

    function _parseOptions(valueAccessor) {
        var options = valueAccessor();

        if ($.type(options) !== "object") {
            options = {
                value: options
            };
        }

        return options;
    }

    function _setDefaultOption(options, field, defaultValue) {
        if (!options.hasOwnProperty(field)) {
            options[field] = defaultValue;
        }
    }

    function _shiftDecimal(value, places) {
        if (!value || !places || places <= 0) {
            return value;
        }

        if ($.type(value) === "string") {
            value = new Decimal(value);
        }

        return value.times(Decimal.pow(10, places));
    }

    function _parseSymbol(symbol) {
        var value = ko.unwrap(symbol);
        if ($.type(value) === "object") {
            if (!value.hasOwnProperty("symbol")) {
                throw new Error("Expected object to have symbol property!");
            }

            value = value.symbol;
        }

        return value;
    }
});

