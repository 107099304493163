define('common/extensions/koTooltipBinding',["jquery", "knockout"], function ($, ko) {
    "use strict";

    ko.bindingHandlers.tooltip = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var $element = $(element);
            var $tooltip = null;

            $element.hover(function () {
                $(".swift-tooltip").remove();

                var options = ko.unwrap(valueAccessor());
                var value, css, position, showOnOverflow, selector;

                if ($.type(options) === "object") {
                    value = ko.unwrap(options.value);
                    css = ko.unwrap(options.css);
                    position = ko.unwrap(options.position);
                    showOnOverflow = ko.unwrap(options.showOnOverflow);
                    selector = ko.unwrap(options.selector);
                } else {
                    value = ko.unwrap(options);
                }

                if (showOnOverflow && element.scrollWidth <= Math.round($element.innerWidth())) {
                    return;
                }

                if ($.type(value) === "function") {
                    value = value();
                }

                if (position !== "top" && position !== "bottom" && position !== "left" && position !== "right") {
                    position = "top";
                }

                if (!$.defined(value) || value === "") {
                    return;
                }

                css = $.type(css) === "string" ? " " + css : "";
                $tooltip = $(
                    "<div class='swift-tooltip " + position + css + "'>" +
                    "    <div class='message'></div>" +
                    "    <div class='arrow'></div>" +
                    "</div>"
                );
                $tooltip.find(".message").html(value);

                ko.applyBindings(viewModel, $tooltip[0]);

                $("body").append($tooltip);

                var $positionOverElement = selector ? $($element.find(selector)[0]) : $element;

                if (position === "bottom") {
                    _positionToolTipVertical($positionOverElement, $tooltip, false);
                } else if (position === "left") {
                    _positionToolTipHorizontal($positionOverElement, $tooltip, true);
                } else if (position === "right") {
                    _positionToolTipHorizontal($positionOverElement, $tooltip, false);
                } else if (position === "top") {
                    _positionToolTipVertical($positionOverElement, $tooltip, true);
                }

                $tooltip.fadeIn();
            }, function () {
                if ($tooltip) {
                    $tooltip.stop().fadeOut(100, function () {
                        ko.cleanNode($tooltip[0]);
                        $tooltip.remove();
                    });
                }
            });

            $element.click(function () {
                if ($tooltip) {
                    $tooltip.remove();
                }
            });
        }
    };

    function _positionToolTipVertical($element, $tooltip, positionAbove) {
        var offset = $element.offset();

        var elementHalfWidth = $element.outerWidth() / 2;
        var tooltipHalfWidth = $tooltip.outerWidth() / 2;

        var left = offset.left + elementHalfWidth - tooltipHalfWidth;
        var top;
        var right = left + $tooltip.outerWidth();
        var rightOverlap = $tooltip.parent().outerWidth() - right;

        if (rightOverlap < 0) {
            left += rightOverlap - 4;
        } else if (left < 0) {
            left = 0;
        }

        if (positionAbove) {
            top = -$tooltip.outerHeight() - 8;
        } else {
            top = $element.outerHeight() + 8;
        }

        $tooltip.css("left", left);
        $tooltip.css("top", offset.top + top);

        $tooltip.show();
        var tooltipOffset = $tooltip.offset();
        $tooltip.hide();

        $tooltip.find(".arrow").css({
            left: offset.left - tooltipOffset.left + $element.innerWidth() / 2 - 8
        });
    }

    function _positionToolTipHorizontal($element, $tooltip, positionLeft) {
        var offset = $element.offset();

        var elementHalfHeight = $element.outerHeight() / 2;
        var tooltipHalfHeight = $tooltip.outerHeight() / 2;

        var top = offset.top + elementHalfHeight - tooltipHalfHeight;
        var bottom = top + $tooltip.outerHeight();
        var bottomOverlap = $tooltip.parent().outerHeight() - bottom;

        if (bottomOverlap < 0) {
            top += bottomOverlap - 4;
        } else if (top < 0) {
            top = 0;
        }

        var offsetLeft = $tooltip.outerWidth() + 8;
        if (positionLeft) {
            offsetLeft = -offsetLeft;
        }

        $tooltip.css({
            left: offset.left + offsetLeft,
            top: top
        });

        $tooltip.show();
        var tooltipOffset = $tooltip.offset();
        $tooltip.hide();

        $tooltip.find(".arrow").css({
            top: offset.top - tooltipOffset.top + $element.innerHeight() / 2 - 8
        });
    }
});

