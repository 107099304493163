define('common/extensions/jqTextExtensions',["jquery", "knockout"], function ($, ko) {
    "use strict";

    $.extend({
        capitalize: function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        isNonEmptyString: function (value) {
            return $.type(value) === "string" && value.length > 0;
        },

        lcfirst: function (s) {
            return s.charAt(0).toLowerCase() + s.slice(1);
        },

        lPad: function (n, padding) {
            var res = "";

            n = (n + "").split();

            while (res.length < padding) {
                res = (n.length > 0 ? n.splice(-1) + res : "0" + res);
            }

            return res;
        },

        regexpEscape: function (s) {
            return s.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        },

        ucfirst: function (s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
        }
    });
});

