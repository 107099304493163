// Based on articles on the Knockout wiki and "Asynchronous computed observables in Knockout.js"
// by Daniel Earwicker (https://smellegantcode.wordpress.com)
define('common/extensions/koAsyncExtension',["jquery", "knockout"], function ($, ko) {
    "use strict";

    ko.extenders.async = function (target, initialValue) {
        var value = ko.observable(initialValue), currentDeferred;
        value.inProgress = ko.observable(false);

        var newComputed = ko.computed(function () {
            if (currentDeferred) {
                currentDeferred.reject();
                currentDeferred = null;
            }

            var newValue = target();
            if (newValue && $.type(newValue.done) === "function") {
                value.inProgress(true);
                currentDeferred = $.Deferred().done(function (data) {
                    value(data);
                    value.inProgress(false);
                });
                newValue.done(currentDeferred.resolve);
            } else {
                value(newValue);
                value.inProgress(false);
            }
        });

        value.dispose = function () {
            newComputed.dispose();
            target.dispose();
        };

        return value;
    };
});

