define('common/extensions/koAnimationBindings',["jquery", "knockout"], function ($, ko) {
    "use strict";

    var SLIDE_DURATION = 500;
    var FADE_OPTIONS = {duration: SLIDE_DURATION, queue: false};

    ko.bindingHandlers.slideFade = {
        init: slideFade,
        update: slideFade
    };

    function slideFade(element, valueAccessor) {
        var $element = $(element);

        var value = ko.unwrap(valueAccessor());
        var currentMode = $element.data("slide-fade-mode");
        var newMode = value ? "open" : "closed";
        if (currentMode && currentMode === newMode) {
            return;
        }
        if (value) {
            $element.stop(true, true).fadeIn(FADE_OPTIONS).css("display", "none").slideDown(SLIDE_DURATION);
        } else {
            $element.stop(true, true).fadeOut(FADE_OPTIONS).slideUp(SLIDE_DURATION);
        }
        $element.data("slide-fade-mode", newMode);
    }

    ko.bindingHandlers.fadeVisible = {
        init: function (element, valueAccessor) {
            var value = valueAccessor();
            $(element).toggle(ko.unwrap(value));
        },

        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var options = valueAccessor();
            var value;
            if ($.type(options) === "object") {
                value = options.value;
                delete options.value;
            } else {
                value = options;
                options = {};
            }

            value = ko.unwrap(value);

            if (element._fadeVisibleTimeout) {
                clearTimeout(element._fadeVisibleTimeout);
            }
            element._fadeVisibleTimeout = setTimeout(function () {
                var $element = $(element);

                // Debugging
                if ($.type($element.finish) !== "function") {
                    var type = $.type($element.finish);
                    var maskType = $.type($element);
                    throw new Error(
                        "For some reason, $element.finish is of type " + type + ". " +
                        "Mask is of type " + maskType + " and has the value " + $element
                    );
                }

                $(element).finish()[value ? "fadeIn" : "fadeOut"](options);
            }, 100);
        }
    };

    ko.bindingHandlers.slideVisible = {
        init: function (element, valueAccessor) {
            var $element = $(element);
            var $selector = $element;

            if ($element.is("tr")) {
                $element.children("td").addClass("slidable");
                $element.children("td").wrapInner("<div class='slide-visible-helper'></div>");
                $selector = $element.children("td").children(".slide-visible-helper");
            }

            // Debugging
            if ($.type($selector.finish) !== "function") {
                var type = $.type($selector.finish);
                var maskType = $.type($selector);
                throw new Error(
                    "For some reason, $element.finish is of type " + type + ". " +
                    "Mask is of type " + maskType + " and has the value " + $selector
                );
            }

            $selector.finish().toggle(ko.unwrap(valueAccessor()));
        },
        update: function (element, valueAccessor) {
            var $element = $(element);
            var $selector = $element;

            if ($element.is("tr")) {
                var $cells = $element.children("td");
                $selector = $cells.children(".slide-visible-helper");

                if (ko.unwrap(valueAccessor())) {
                    $cells.addClass("slide-visible");
                    $cells.removeClass("slide-hidden");
                } else {
                    $cells.addClass("slide-hidden");
                    $cells.removeClass("slide-visible");
                }
            }

            $selector.stop();
            ko.unwrap(valueAccessor()) ? $selector.slideDown() : $selector.slideUp();
        }
    };
});

