define('common/select/countrySelector',[
    "jquery",
    "knockout",
    "common/select/searchSelector",
    "common/search/countrySearcher"
], function ($, ko, SearchSelector, CountrySearcher) {
    "use strict";

    function CountrySelector(options, id) {
        options = options || {};
        var searcher = new CountrySearcher(options.searcher);
        var that = SearchSelector.call(this, options, id || "country-selector", searcher);

        that.searcher.onInit = function (data) {
            if (data.userCountry && !that.isSelected()) {
                that.selectResult(data.userCountry);
            }
        };

        that.searcher.whitelist.subscribe(_onAllowedCountriesChange.bind(that));
        that.searcher.blacklist.subscribe(_onAllowedCountriesChange.bind(that));
    }

    function _onAllowedCountriesChange() {
        var that = this;

        if (that.isSelected() && !that.searcher.countryIsAllowed(that.val())) {
            that.clearSelection();
        }
    }

    CountrySelector.prototype = Object.create(SearchSelector.prototype);

    CountrySelector.prototype._readParams = function (params) {
        var that = this;

        SearchSelector.prototype._readParams.call(that, params);
    };

    CountrySelector.prototype._countryDoesNotMatch = function (value) {
        var that = this;

        if (!value) {
            return false;
        } else if (!that.searcher.countryIsAllowed(value)) {
            return true;
        }

        value = {countryId: value.id};

        return SearchSelector.prototype._countryDoesNotMatch.call(that, value);
    };

    return CountrySelector;
});

