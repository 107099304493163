define('common/widgets/dropdowns',["jquery"], function ($) {
    "use strict";

    $(document).on("keyup.dropdown", function (event) {
        if (event.keyCode === 27) {
            $(".dropdown").dropdown("close");
        }
    });

    $(document).on("click.dropdown-close", function (event) {
        $(".dropdown").dropdown("close");
    });

    $(document).on("click.dropdown-label", ".dropdown > .dropdown-label", function (event) {
        $($(this).parent()).dropdown("toggle");
        event.preventDefault();
        event.stopPropagation();
    });

    $(document).on("click.dropdown-label", function (event) {
        event.stopPropagation();
    });

    $(document).on("click.dropdown-label", ".dropdown > .dropdown-list > li.selected", function (event) {
        event.preventDefault();
    });

    $.fn.extend({
        dropdown: function (command) {
            var $this = $(this);

            if (!command) {
                return;
            }

            var $list = $this.children(".dropdown-list").stop(true, true);

            var isOpen = $this.hasClass("open");
            if (command === "open" || (command === "toggle" && !isOpen)) {
                $(".dropdown").not($this).dropdown("close");
                $list.fadeIn();
                $this.addClass("open");
            } else if (command === "close" || (command === "toggle" && isOpen)) {
                $list.fadeOut();
                $this.removeClass("open");
            }
        }
    });
});

