define('common/search/circuitSearcher',["jquery", "knockout", "js-routes", "common/search/searcher"], function ($, ko, jsRoutes, Searcher) {
    "use strict";

    function CircuitSearcher(country, options) {
        this.waitingOnCountryI18nKey = "circuit-selector.waiting-on.country";

        var that = Searcher.call(this, "circuit", options, country);

        that.countries = ko.observableArray();
        that.selectedCircuit = ko.observable();

        return that;
    }

    CircuitSearcher.route = jsRoutes.controllers.common.search.CircuitSearch;

    CircuitSearcher.prototype = Object.create(Searcher.prototype);

    CircuitSearcher.prototype.initialize = function (data) {
        var that = this;

        Searcher.prototype.initialize.call(that, data);
        that.countries(data.countries || []);
    };

    CircuitSearcher.prototype._initRoute = function () {
        var that = this;

        return CircuitSearcher.route.initialize(that.withUnderlyingUser);
    };

    CircuitSearcher.prototype._searchRoute = function () {
        var that = this;

        var searchTerm = that.searchTerm();
        if (!searchTerm) {
            return null;
        }

        return CircuitSearcher.route.search(that.withUnderlyingUser, searchTerm, that.countryId());
    };

    return CircuitSearcher;
});

