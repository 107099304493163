define('common/extensions/dateExtensions',["jquery", "js-messages"], function ($, jsMessages) {
    "use strict";

    var ONE_DAY = 1000 * 60 * 60 * 24;

    Date.prototype.addDays = function (days) {
        var that = this;

        var d = new Date(that.valueOf());
        d.setDate(d.getDate() + parseInt(days));

        return d;
    };

    Date.prototype.daysBetween = function (other) {
        var that = this;

        return Math.round(Math.abs((that.getTime() - other.getTime()) / ONE_DAY));
    };

    Date.prototype.compareTo = function (other) {
        var that = this;

        return Date.compare(that, other);
    };

    Date.prototype.isBefore = function (other) {
        var that = this;

        return that.compareTo(other) < 0;
    };

    Date.prototype.isAfter = function (other) {
        var that = this;

        return that.compareTo(other) > 0;
    };

    Date.prototype.isEqualTo = function (other) {
        var that = this;

        return that.compareTo(other) === 0;
    };

    Date.prototype.atStartOfDay = function () {
        var that = this;

        that.setHours(0, 0, 0, 0);
        return that;
    };

    Date.prototype.getMinLocaleDayName = function () {
        var that = this;

        return getLocaleDayName(that.getDay(), true);
    };

    Date.prototype.getLocaleDayName = function () {
        var that = this;

        return getLocaleDayName(that.getDay(), false);
    };

    function getLocaleDayName(day, isAbbreviation) {
        var name = "";
        switch (day) {
            case (0):
                name = "sunday";
                break;
            case (1):
                name = "monday";
                break;
            case (2):
                name = "tuesday";
                break;
            case (3):
                name = "wednesday";
                break;
            case (4):
                name = "thursday";
                break;
            case (5):
                name = "friday";
                break;
            case (6):
                name = "saturday";
                break;
        }

        return jsMessages("day-of-week." + name + (isAbbreviation ? ".abbreviation" : ""));
    }

    Date.prototype.toDateString = function () {
        var that = this;

        return $.dateToString(that);
    };

    Date.prototype.toLocaleDateString = function () {
        var that = this;

        return $.toLocaleDate(that);
    };

    Date.prototype.toLocaleShortDateString = function () {
        var that = this;

        return $.toLocaleShortDate(that);
    };

    Date.prototype.toLocaleDateTimeString = function () {
        var that = this;

        return $.toLocaleDateTime(that);
    };

    Date.compare = function (date, other) {
        if ($.type(date) === "string") {
            date = $.stringToDate(date);
        }

        if ($.type(other) === "string") {
            other = $.stringToDate(other);
        }

        if ($.type(date) !== "date" || $.type(other) !== "date") {
            return 1;
        }

        return date.getTime() - other.getTime();
    };

    Date.max = function () {
        if (arguments.length === 0) {
            return undefined;
        }

        var maxDate = arguments[0];
        Array.prototype.forEach.call(arguments, function (date) {
            if (Date.compare(date, maxDate) > 0) {
                maxDate = date;
            }
        });

        return maxDate;
    };

    Date.min = function () {
        if (arguments.length === 0) {
            return undefined;
        }

        var minDate = arguments[0];
        Array.prototype.forEach.call(arguments, function (date) {
            if (Date.compare(date, minDate) < 0) {
                minDate = date;
            }
        });

        return minDate;
    };

    Date.today = function () {
        var date = new Date();
        date.setHours(0, 0, 0, 0);

        return date;
    };
});

