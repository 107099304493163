define('common/vm',[
    "jquery",
    "knockout",
    "js-routes",
    "js-messages",
    "stacktrace",
    "toastr",
    "common/boxOfficeReports",
    "common/select/countrySelector",
    "common/select/theaterSelector",
    "common/select/userSelector",
    "common/widgets/navBar",
    "common/widgets/spinner",
    "common/extensions/dateExtensions",
    "common/extensions/jqCleanWhitespacePlugin",
    "common/extensions/jqMaxWidthPlugin",
    "common/extensions/jqExtensions",
    "common/extensions/jqNavigationExtensions",
    "common/extensions/jqPropertiesExtensions",
    "common/extensions/jqTextExtensions",
    "common/extensions/koAnimationBindings",
    "common/extensions/koDatepickerBindings",
    "common/extensions/koFileUploadBinding",
    "common/extensions/koKeyboardBindings",
    "common/extensions/koNumberBindings",
    "common/extensions/koSortableHeaderBinding",
    "common/extensions/koTooltipBinding",
    "common/extensions/koAsyncExtension",
    "common/extensions/koDateExtensions",
    "common/extensions/koIntegerExtension",
    "common/widgets/dropdowns",
    "common/widgets/modals"
], function (
    $,
    ko,
    jsRoutes,
    jsMessages,
    StackTrace,
    Toastr,
    BoxOfficeReports,
    CountrySelector,
    TheaterSelector,
    UserSelector,
    NavBar,
    Spinner
) {
    "use strict";

    function VM(options) {
        var that = this;

        that.options = options || {};

        that.isLoading = Spinner.isLoading;
        that._isInitialized = ko.observable(false);

        that.navBar = new NavBar("#nav-bar");
        if ($("#insider-nav-bar").length > 0) {
            that.insiderNavBar = new NavBar("#insider-nav-bar");
        }

        if ($("#has-country-theater-widget").val() === "true") {
            that.country = new CountrySelector({
                isRequired: true,
                useLocalStorage: true
            });
            that.theater = new TheaterSelector(that.country, {
                isRequired: true,
                useLocalStorage: true
            });
        }

        if ($("#temp-user-selection-modal").length > 0) {
            that.tempUser = new UserSelector({
                searcher: {
                    withUnderlyingUser: true
                }
            }, "temp-user-selector");
            that.tempUser.onChange = tempLoginUser;
            that.tempUser.searcher.country.searcher.blacklist([]);
        }

        that._pendingRequests = [];
        that._initAjax();

        return that;
    }

    VM.prototype.user = function () {
        return $("#current-username").val();
    };

    VM.prototype.country = function () {
        return $("#current-country-id").val();
    };

    // Init

    VM.prototype._initAjax = function () {
        var that = this;

        $.ajaxSetup({
            cache: false
        });

        var unloaded = false;
        $(window).bind('beforeunload', function() {
            unloaded = true;
        });

        $(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
            if (unloaded || thrownError === "abort") {
                return;
            }

            if (jqXHR.status === 401) {
                var pathname = window.location.pathname;
                var search = window.location.search;
                var hash = window.location.hash;

                $.navigateTo(jsRoutes.controllers.common.Login.login(pathname + search + hash));
                return;
            }

            Toastr.error("Connection error; please try again");
        });
    };

    // Page Spinner

    VM.prototype.requestData = function (route, params, callback) {
        var that = this;

        Spinner.start();

        var xhr = $.ajaxr(route, params).done(function (data) {
            if ($.type(callback) === "function") {
                callback(data);
            } else if (!$.defined(callback)) {
                that.loadRequestedData(data);
            }

            if (that._isInitialized()) {
                Spinner.stop();
            }
        });

        that.addRequestToWaitlist(xhr);

        return xhr;
    };

    VM.prototype.loadRequestedData = function (data) {
        console.log("Received " + JSON.stringify(data));
    };

    VM.prototype.bindToView = function () {
        var that = this;

        that.boxOfficeReports = BoxOfficeReports;
        if (!that.options.disableBoxOfficeReports) {
            that.boxOfficeReports.start();
        }

        ko.applyBindings(that);

        that.formatMultiSelects();

        $.when.apply($, that._pendingRequests).done(function () {
            that._pendingRequests = [];
            that._isInitialized(true);
            Spinner.stop();
        });
    };

    VM.prototype.addRequestToWaitlist = function (request) {
        var that = this;

        if (!that._isInitialized()) {
            that._pendingRequests.push(request);
        }
    };

    // Temp Login

    VM.prototype.showTempUserModal = function () {
        var that = this;

        $("#temp-user-selection-modal").modal("open");
    };

    function tempLoginUser(user) {
        if (!user) {
            return;
        }

        Spinner.start();

        $.ajaxr(jsRoutes.controllers.common.Login.loginTempUser(user.id)).done(function () {
            location.reload();
        });
    };

    VM.prototype.logoutTempUser = function () {
        Spinner.start();

        $.ajaxr(jsRoutes.controllers.common.Login.logoutTempUser()).done(function () {
            location.reload();
        });
    };

    // Navigation

    VM.prototype.signOut = function () {
        var that = this;

        Spinner.start();
        $.ajaxr(jsRoutes.controllers.common.Login.tryLogout()).done(function (response) {
            if (response.hasActiveSession) {
                that.showSignOutConfirm();
            } else if (response.isLoggedOut) {
                $.navigateTo(jsRoutes.controllers.common.Login.login());
            } else {
                throw "Unrecognized response to tryLogout: " + response;
            }
        });
    };

    VM.prototype.confirmedSignOut = function () {
        $.navigateTo(jsRoutes.controllers.common.Login.logout());
    };

    VM.prototype.showSignOutConfirm = function () {
        var that = this;

        that.navBar.close();
        if (that.insiderNavBar) {
            that.insiderNavBar.close();
        }
        $("#logout-confirm-modal").modal("open");
    };

    VM.prototype.cancelSignOutConfirm = function () {
        Spinner.stop();
    };

    VM.prototype.changeLocale = function (localeCode) {
        $.ajaxr(jsRoutes.controllers.Application.changeLocale(localeCode)).done(function () {
            window.location.reload();
        });
    };

    window.onerror = function (message, source, line, column, error) {
        if (message.indexOf('Script error.') > -1) {
            return;
        }

        try {
            StackTrace.fromError(error).then(function (stackFrames) {
                var stackTrace = stackFrames.map(function (frame) {
                    return frame.toString();
                }).join("\n");

                reportError(message, source, line, column, stackTrace);
            })["catch"](function (e) {
                reportError(message, source, line, column, e ? e.message || null : null);
            });
        } catch (e) {
            reportError(message, source, line, column, "No stack trace: " + e.message);
        }
    };

    function reportError(message, source, line, column, stackTrace) {
        var route = jsRoutes.controllers.common.JavascriptErrorReporter.report();

        $.ajax({
            contentType: "application/json",
            data: JSON.stringify({
                url: window.location.href,
                message: message,
                source: source,
                line: line,
                column: column,
                stackTrace: stackTrace,
                userAgent: navigator ? navigator.userAgent : undefined
            }),
            processData: false,
            type: route.type,
            url: route.url
        });
    }

    VM.prototype.formatMultiSelects = function () {
        $(".multi-select").maxWidth("update", "> *");
    };

    return VM;
});

