define('common/select/circuitSelector',[
    "jquery",
    "knockout",
    "common/select/searchSelector",
    "common/search/circuitSearcher"
], function ($, ko, SearchSelector, CircuitSearcher) {
    "use strict";

    function CircuitSelector(country, options, id) {
        options = options || {};

        var searcher = new CircuitSearcher(country, options.searcher);
        SearchSelector.call(this, options, id || "circuit-selector", searcher);
    }

    CircuitSelector.prototype = Object.create(SearchSelector.prototype);

    return CircuitSelector;
});

