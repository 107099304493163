define('common/extensions/jqPropertiesExtensions',["jquery", "knockout", "decimal"], function ($, ko, Decimal) {
    "use strict";

    $.extend({
        properties: function (target, data) {
            for (var i = 2; i < arguments.length; i++) {
                var property = arguments[i], name = property.name, initialValue;

                if (property.doNotRead) {
                    initialValue = $.defined(property.defaultValue) ? property.defaultValue
                        : property.nullValue;
                } else {
                    if (data && data.hasOwnProperty(name)) {
                        initialValue = data[name];
                    } else {
                        initialValue = property.nullValue || null;
                    }

                    if ($.defined(property.defaultValue) && !initialValue) {
                        initialValue = property.defaultValue;
                    }

                    if (property.isDate) {
                        target[name + "String"] = $.dateToString(initialValue);
                        initialValue = $.stringToDate(initialValue);
                    } else if (property.isDateTime) {
                        target[name + "String"] = initialValue;
                        initialValue = $.stringToDateTime(initialValue);
                    } else if (property.isCurrency) {
                        if ($.defined(initialValue) && $.type(initialValue) !== "string") {
                            throw new Error("Cannot instantiate currency property " + name
                                + ", must be a string to ensure accuracy!");
                        }
                    } else if (property.isPercent) {
                        if ($.defined(initialValue) && $.type(initialValue) !== "string") {
                            throw new Error("Cannot instantiate percent property " + name
                                + ", must be a string to ensure accuracy!");
                        }
                    } else if (property.isBoolean) {
                        initialValue = !!initialValue;
                    }
                }

                if (property.isObservable) {
                    if (property.isEmail) {
                        target[name] = ko.observable(initialValue).extend({email: true});
                        target["_initial" + $.ucfirst(name)] = ko.observable(target[name]());
                    } else if (property.isArray) {
                        if (!$.defined(initialValue)) {
                            initialValue = [];
                        } else if (!$.isArray(initialValue)) {
                            throw new Error(initialValue + " is not an array!");
                        }
                        target[name] = ko.observableArray(initialValue);
                        target["_initial" + $.ucfirst(name)] = ko.observableArray(target[name]().slice());
                    } else if (property.isDate) {
                        target[name] = ko.observable(initialValue).extend({formatAsDate: true});
                        target["_initial" + $.ucfirst(name)] = ko.observable(target[name]());
                    } else if (property.isDateTime) {
                        target[name] = ko.observable(initialValue).extend({formatAsDateTime: true});
                        target["_initial" + $.ucfirst(name)] = ko.observable(target[name]());
                    } else {
                        target[name] = ko.observable(initialValue);
                        target["_initial" + $.ucfirst(name)] = ko.observable(target[name]());
                    }
                    $._propertiesAssignChangeWatcher(target, name, property);
                } else {
                    target[name] = initialValue;
                }
            }
        },

        _propertiesAssignChangeWatcher: function (target, name, property) {
            target[name + "HasChanged"] = ko.pureComputed(function () {
                var current = target[name]();
                var initial = target["_initial" + $.ucfirst(name)]();

                if (property.isArray) {
                    return !$.arraysEqual(current, initial);
                }

                var hasBothValues = $.defined(current) && $.defined(initial);
                if (hasBothValues) {
                    var bothNonEmtpy = current !== "" && initial !== "";
                    if ((property.isCurrency || property.isPercent) && bothNonEmtpy) {
                        return !(new Decimal(current).equals(new Decimal(initial)));
                    } else if (property.isDate) {
                        return !current.atStartOfDay().isEqualTo(initial.atStartOfDay());
                    } else if (property.isDateTime) {
                        return !current.isEqualTo(initial);
                    }
                }

                return current !== initial;
            });
        },

        resetProperty: function (target, name) {
            target[name](target["_initial" + $.ucfirst(name)]());
        }
    });
});
