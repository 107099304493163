define('common/extensions/jqExtensions',[
    "jquery",
    "knockout",
    "js-routes",
    "decimal",
    "toastr",
    "js-messages",
    "common/utils/dateTimeFormatsByLocaleId"
], function (
    $,
    ko,
    jsRoutes,
    Decimal,
    Toastr,
    jsMessages,
    DateTimeFormatsByLocaleId
) {
    "use strict";

    var oneDay = 1000 * 60 * 60 * 24;

    var currentLocale;

    $.extend({
        ajaxr: function (route, data) {
            var params = {
                type: route.type,
                url: route.url
            };
            if ($.type(data) === "object" || $.type(data) === "array") {
                params.contentType = "application/json";
                params.data = JSON.stringify(data);
                params.processData = false;
            }
            return $.ajax(params);
        },

        defined: function (value) {
            return $.type(value) !== "undefined" && $.type(value) !== "null";
        },

        cleanDate: function (date) {
            if ($.type(date) !== "date") {
                throw new Error("Can't clean something that isn't a date!");
            }

            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        },

        dateCompare: function (date, other) {
            if ($.type(date) === "string") {
                date = $.stringToDate(date);
            }

            if ($.type(other) === "string") {
                other = $.stringToDate(other);
            }

            if (!$.defined(date) && !$.defined(other)) {
                return 0;
            } else if (!$.defined(date)) {
                return -1;
            } else if (!$.defined(other)) {
                return 1;
            }

            return date.getTime() - other.getTime();
        },

        slideFadeToggle: function (element, horizontal) {
            var $element = element instanceof $ ? element : $(element);

            var params = {};
            params.opacity = "toggle";
            params[horizontal ? "width" : "height"] = "toggle";

            $element.stop(true, true).animate(params, 400);
        },

        /* Assumes yyyy-MM-dd format. */
        stringToDate: function (dateString, splitOn) {
            if ($.type(dateString) === "date") {
                return dateString;
            }

            if ($.type(dateString) !== "string" || dateString.length === 0) {
                return null;
            }

            splitOn = splitOn || "-";
            var dateElements = dateString.split(splitOn);

            return new Date(dateElements[0], parseInt(dateElements[1]) - 1, dateElements[2], 0, 0, 0, 0);
        },

        datesMatch: function (date, other) {
            if (!$.defined(date) && !$.defined(other)) {
                return true;
            }

            if ($.type(date) !== $.type(other) || !$.defined(date) || !$.defined(other)) {
                return false;
            }

            return date.endDateString() === other.endDateString();
        },

        stringToDateTime: function (dateString, splitDateOn, splitTimeOn) {
            if ($.type(dateString) !== "string" || dateString.length === 0) {
                return null;
            }

            splitDateOn = splitDateOn || "-";
            splitTimeOn = splitTimeOn || ":";

            var bothElements = dateString.split(/[T ]/, 2);
            var dateElements = bothElements[0].split(splitDateOn);
            var timeElements = bothElements.length > 1 ? bothElements[1].split(splitTimeOn) : null;

            var year = dateElements[0];
            var month = parseInt(dateElements[1]) - 1;
            var day = dateElements[2];

            var hour = timeElements && timeElements.length > 0 ? timeElements[0] : 0;
            var minute = timeElements && timeElements.length > 1 ? timeElements[1] : 0;
            var second = timeElements && timeElements.length > 2 ? timeElements[2] : 0;

            return new Date(year, month, day, hour, minute, second, 0);
        },

        stringToTime: function (timeString, splitTimeOn) {
            if ($.type(timeString) !== "string" || timeString.length === 0) {
                return null;
            }

            splitTimeOn = splitTimeOn || ":";

            var timeElements = timeString.split(splitTimeOn);
            var hour = timeElements && timeElements.length > 0 ? timeElements[0] : 0;
            var minute = timeElements && timeElements.length > 1 ? timeElements[1] : 0;
            var second = timeElements && timeElements.length > 2 ? timeElements[2] : 0;

            var date = $.today();
            date.setHours(hour);
            date.setMinutes(minute);
            date.setSeconds(second);

            return date;
        },

        dateTimeToString: function (dt, joinWith) {
            if ($.type(dt) === "string") {
                return dt;
            }

            if ($.type(dt) !== "date") {
                return undefined;
            }

            var date = $.dateToString(dt, joinWith ? joinWith : "-");

            var timeElements = [dt.getHours(), dt.getMinutes(), dt.getSeconds()];
            var time = timeElements.map(function (element) {
                return $.lPad(element, 2);
            }).join(":");

            return date + "T" + time;
        },

        /* Converts to yyyy-MM-dd format. */
        dateToString: function (d, joinWith) {
            if ($.type(d) === "string") {
                return d;
            }

            if ($.type(d) !== "date") {
                return null;
            }

            if (!joinWith) {
                joinWith = "-";
            }

            return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(function (n) {
                return $.lPad(n, 2);
            }).join(joinWith);
        },

        daysDiff: function (a, b) {
            if ($.type(a) === "string") {
                a = $.stringToDate(a);
            }

            if ($.type(b) === "string") {
                b = $.stringToDate(b);
            }

            if (!$.defined(a) || !$.defined(b)) {
                return undefined;
            }

            return Math.round(Math.abs((b.getTime() - a.getTime()) / oneDay));
        },

        displayDate: function (date) {
            if ($.type(date) === "string") {
                date = $.stringToDate(date);
            }

            return $.getMonthNames()[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
        },

        toLocaleDate: function (date) {
            return $.formatDate($.getDateFormatForLocale(), date);
        },

        toLocaleShortDate: function (date) {
            var dateFormat = $.getDateFormatForLocale();

            dateFormat = dateFormat.replace(new RegExp("y+"), "");
            dateFormat = dateFormat.replace(new RegExp("^[^Md]|[^Md]$"), "");

            return $.formatDate(dateFormat, date);
        },

        formatDate: function (format, date) {
            if ($.type(date) === "string") {
                date = $.stringToDateTime(date);
            }

            if (!$.defined(date)) {
                return null;
            }

            var year = date.getFullYear() + "";
            var month = date.getMonth() + 1;
            var day = date.getDate();

            var hour = date.getHours();
            var moduloHour = hour == 12 ? hour : hour % 12;
            moduloHour = moduloHour === 0 ? 12 : moduloHour;
            var minute = date.getMinutes();
            var second = date.getSeconds();

            var formattedDate = format;

            formattedDate = formattedDate.replace("yyyy", year);
            formattedDate = formattedDate.replace("yy", year.slice(2, 4));

            formattedDate = formattedDate.replace("MM", $.lPad(month, 2));
            formattedDate = formattedDate.replace("M", month);

            formattedDate = formattedDate.replace("dd", $.lPad(day, 2));
            formattedDate = formattedDate.replace("d", day);

            formattedDate = formattedDate.replace("hh", $.lPad(moduloHour, 2));
            formattedDate = formattedDate.replace("h", moduloHour);
            formattedDate = formattedDate.replace("HH", $.lPad(hour, 2));
            formattedDate = formattedDate.replace("H", hour);

            formattedDate = formattedDate.replace("mm", $.lPad(minute, 2));
            formattedDate = formattedDate.replace("m", minute);

            formattedDate = formattedDate.replace("ss", $.lPad(second, 2));
            formattedDate = formattedDate.replace("s", second);

            formattedDate = formattedDate.replace("tt", hour >= 12 ? 'PM' : 'AM');

            return formattedDate;
        },

        toLocaleDateTime: function (date) {
            return $.formatDate($.getDateTimeFormatForLocale(), date);
        },

        displayLocaleTime: function (date) {
            return $.formatDate($.getTimeFormatForLocale(), date);
        },

        getDateFormatForLocale: function () {
            var currentLocale = $.getCurrentLocale();

            if (!currentLocale) {
                return "yyyy-MM-dd";
            }

            return DateTimeFormatsByLocaleId.get(currentLocale, "shortDateFormat") || "yyyy-MM-dd";
        },

        getTimeFormatForLocale: function () {
            var currentLocale = $.getCurrentLocale();

            if (!currentLocale) {
                return "HH:mm";
            }

            return DateTimeFormatsByLocaleId.get(currentLocale, "shortTimeFormat") || "HH:mm";
        },

        getDateTimeFormatForLocale: function () {
            var currentLocale = $.getCurrentLocale();

            var dateFormat = $.getDateFormatForLocale();
            var timeFormat = $.getTimeFormatForLocale();

            var dateTimeFormat = "{1}, {0}";
            if (currentLocale) {
                dateTimeFormat = DateTimeFormatsByLocaleId.get(currentLocale, "shortDateTimeFormat");
            }

            dateTimeFormat = dateTimeFormat.replace("\{1\}", dateFormat);
            dateTimeFormat = dateTimeFormat.replace("\{0\}", timeFormat);

            return dateTimeFormat;
        },

        getCurrentLocale: function () {
            if (!currentLocale) {
                currentLocale = $("#current-locale").val();
            }

            return currentLocale;
        },

        testEmailAddress: function (observable, skipAjaxCondition) {
            return $.testEndpoint(
              jsRoutes.controllers.Application.emailAddressLooksGood,
              skipAjaxCondition,
              true,
              observable
            );
        },

        testEndpoint: function (getRoute, skipAjaxCondition, initialValue, ...observables) {
            if ($.type(skipAjaxCondition) !== "function") {
                skipAjaxCondition = null;
            }

            return ko.computed(function () {
                if (skipAjaxCondition && skipAjaxCondition()) {
                    return false;
                }

                var values = observables.map(function (observable) {
                    var value = observable();
                    return $.defined(value) ? value : "";
                });

                if (values.some(value => !value)) {
                    return false;
                }

                return $.ajaxr(getRoute(...values));
            }).extend({
                rateLimit: {
                    timeout: 300,
                    method: "notifyWhenChangesStop"
                    // method: $.debounceSubsequentChanges TODO: Knockout version upgrade
                }
            }).extend({
                async: initialValue
            });
        },

        /*
        TODO: Knockout version upgrade
        debounceSubsequentChanges: function (action, timeout) {
            var timeoutInstance;
            return function () {
                if (!timeoutInstance) {
                    action();
                    timeoutInstance = setTimeout(function () {
                        timeoutInstance = undefined;
                    }, timeout);
                } else {
                    clearTimeout(timeoutInstance);
                    timeoutInstance = setTimeout(function () {
                        timeoutInstance = undefined;
                        action();
                    }, timeout);
                }
            };
        },
        */

        firstDayOfWeek: function (date) {
            var dow = date.getDay() - 1;

            if (dow < 0) {
                dow += 7;
            }

            return $.plusDays(date, -dow);
        },

        getQueryParam: function (name) {
            var regex, results;

            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
            results = regex.exec(location.search);

            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        getMonthNames: function () {
            var $monthNames = [
                jsMessages("month.january"),
                jsMessages("month.february"),
                jsMessages("month.march"),
                jsMessages("month.april"),
                jsMessages("month.may"),
                jsMessages("month.june"),
                jsMessages("month.july"),
                jsMessages("month.august"),
                jsMessages("month.september"),
                jsMessages("month.october"),
                jsMessages("month.november"),
                jsMessages("month.december")
            ];

            var monthNames = [];
            $monthNames.each(function ($monthName) {
                monthNames.push($(this).val());
            });

            return monthNames;
        },

        getOrdinal: function (value, withValue) {
            if (!$.isNumeric(value)) {
                return undefined;
            }

            var j = value % 10, k = value % 100, suffix;
            if (j == 1 && k != 11) {
                suffix = "st";
            } else if (j == 2 && k != 12) {
                suffix = "nd";
            } else if (j == 3 && k != 13) {
                suffix = "rd";
            } else {
                suffix = "th";
            }

            return (withValue ? value : "") + suffix;
        },

        isFutureDate: function (date) {
            if ($.type(date) === "string") {
                date = $.stringToDate(date);
            }

            return $.dateCompare(date, $.today()) > 0;
        },

        minDisplayDate: function (date) {
            if ($.type(date) === "string") {
                date = $.stringToDate(date);
            }

            if ($.type(date) !== "date") {
                return null;
            }

            return (date.getMonth() + 1) + "/" + date.getDate();
        },

        monthsDiff: function (a, b) {
            return (b.getFullYear() - a.getFullYear()) * 12 + (b.getMonth() - a.getMonth());
        },

        overrideProperty: function (target, name) {
            target["_initial" + $.ucfirst(name)](target[name]());
        },

        parseValuesFromSelect: function ($element, idField, nameField) {
            var items = [];

            $element.children("option").each(function (index, option) {
                var item = {};

                item[idField || "id"] = $(option).val();
                item[nameField || "name"] = $(option).text();
                items.push(item);

                /*
                 * We remove all children just before knockout applies bindings so knockout does not have to
                 * crawl through a bunch of options just to find nothing that needs binding.
                 */
                $(option).remove();
            });

            return items;
        },

        plusDays: function (date, daysDelta) {
            if ($.type(date) === "string") {
                date = $.stringToDate(date);
            } else if ($.type(date) !== "date") {
                return undefined;
            }

            var newDate = new Date(date.getTime());
            newDate.setDate(date.getDate() + daysDelta);

            return newDate;
        },

        plusMonths: function (date, monthsDelta) {
            if ($.type(date) === "string") {
                date = $.stringToDate(date);
            } else if ($.type(date) !== "date") {
                return undefined;
            }

            var newDate = new Date(date.getTime());
            newDate.setMonth(date.getMonth() + monthsDelta);

            return newDate;
        },

        plusYears: function (date, yearsDelta) {
            if ($.type(date) === "string") {
                date = $.stringToDate(date);
            } else if ($.type(date) !== "date") {
                return undefined;
            }

            var newDate = new Date(date.getTime());
            newDate.setYear(date.getFullYear() + yearsDelta);

            return newDate;
        },

        arraysEqual: function (array1, array2) {
            if ((!array1 && !array2) || (array1.length === 0 && array2.length === 0)) {
                return true;
            }

            if (array1.length !== array2.length) {
                return false;
            }

            for (var i = 0; i < array1.length; i++) {
                var a = array1[i];
                var b = array2[i];

                var itemsEqual = true;
                if ($.type(a) === "array" && $.type(b) === "array") {
                    itemsEqual = $.arraysEqual(a, b);
                } else {
                    itemsEqual = a == b;
                }

                if (!itemsEqual) {
                    return false;
                }
            }

            return true;
        },

        today: function () {
            var date = new Date();
            date.setHours(0, 0, 0, 0);

            return date;
        },

        weeksDiff: function (a, b) {
            return parseInt($.daysDiff($.firstDayOfWeek(a), $.firstDayOfWeek(b)) / 7);
        },

        yearsDiff: function (a, b) {
            return b.getFullYear() - a.getFullYear();
        },

        getMinDate: function () {
            var dates = Array.prototype.slice.call(arguments);

            return new Date(Math.min.apply(null, dates.map(function (date) {
                return date instanceof Date ? date : $.stringToDate(date);
            })));
        },

        getMaxDate: function () {
            var dates = Array.prototype.slice.call(arguments);

            return new Date(Math.max.apply(null, dates.map(function (date) {
                return date instanceof Date ? date : $.stringToDate(date);
            })));
        },

        success: function (key) {
            key = key || "changes-saved";
            Toastr.clear();
            Toastr.success(jsMessages(key));
        },

        warning: function (key) {
            key = key || "error";
            Toastr.clear();
            Toastr.warning(jsMessages(key));
        },

        error: function (key, displayRawInput) {
            key = key || "error";
            Toastr.clear();
            Toastr.error(displayRawInput ? key : jsMessages(key));
        }
    });

    function getMonthNames() {
        var $monthNames = $("#i18n-month-names > input");

        if ($monthNames.length !== 12) {
            throw new Error("Too " + ($monthNames.length < 12 ? "few" : "many") + " months found!");
        }

        var monthNames = [];
        $monthNames.each(function ($monthName) {
            monthNames.push($($).val());
        });

        return monthNames;
    }
});

