define('common/search/distributorSearcher',["jquery", "knockout", "js-routes", "common/search/searcher"], function ($, ko, jsRoutes, Searcher) {
    "use strict";

    function DistributorSearcher(country, options) {
        return Searcher.call(this, "distributor", options, country);
    }

    DistributorSearcher.ROUTE = jsRoutes.controllers.common.search.DistributorSearch;

    DistributorSearcher.prototype = Object.create(Searcher.prototype);

    DistributorSearcher.prototype._initRoute = function () {
        var that = this;

        return DistributorSearcher.ROUTE.initialize(that.withUnderlyingUser);
    };

    DistributorSearcher.prototype._searchRoute = function () {
        var that = this;

        var searchTerm = that.searchTerm();
        if (!searchTerm) {
            return null;
        }

        return DistributorSearcher.ROUTE.search(that.withUnderlyingUser, searchTerm, that.countryId());
    };

    return DistributorSearcher;
});

