define('common/errorPage',["jquery", "common/vm"], function ($, VM) {
    "use strict";

    function ErrorPage() {
        return VM.call(this);
    }

    ErrorPage.prototype = Object.create(VM.prototype);

    return ErrorPage;
});

