define('common/select/userSelector',[
    "jquery",
    "knockout",
    "common/select/searchSelector",
    "common/search/userSearcher"
], function ($, ko, SearchSelector, UserSearcher) {
    "use strict";

    function UserSelector(options, id) {
        id = id || "user-selector";
        options = options || {};
        var that = SearchSelector.call(this, options, id, new UserSearcher(id, options.searcher));

        that.searcher.country.parent = that;
        that.searcher.circuit.parent = that;
        that.searcher.theater.parent = that;
        that.searcher.distributor.parent = that;
    }

    UserSelector.prototype = Object.create(SearchSelector.prototype);

    return UserSelector;
});

