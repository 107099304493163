define('common/extensions/koKeyboardBindings',["jquery", "knockout"], function ($, ko) {
    "use strict";

    ko.bindingHandlers.onEnter = {
        init: getKeypressHandler(13)
    };
    ko.bindingHandlers.onTab = {
        init: getKeypressHandler(9)
    };

    function getKeypressHandler(keyCode) {
        return function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var value = valueAccessor();

            if ($.type(value) !== "function") {
                throw new Error("Value for keypress handler must be a function");
            }

            $(element).keydown(function (event) {
                var eventKeyCode = event.which ? event.which : event.keyCode;

                if (keyCode === eventKeyCode) {
                    return value.call(viewModel, element, event);
                } else {
                    return true;
                }
            });
        };
    }
});

