define('common/extensions/koFileUploadBinding',["jquery", "knockout"], function ($, ko) {
    "use strict";

    ko.bindingHandlers.fileUpload = {
        init: function (element, valueAccessor) {
            $(element).change(function () {
                var file = this.files[0];

                if (ko.isObservable(valueAccessor())) {
                    valueAccessor()(file);
                }
            });
        }
    };
});

